Beacon.JqueryValidators = {
    initialize: function () {
        jQuery.validator.addMethod('zipCode', this.zipCode, 'Please enter a valid 5- or 9-digit ZIP Code™.');
    },
    currentValidator: null,
    destroy: function () {
        // Cleans up all forms and elements, removes validator-specific events
        this.currentValidator.resetForm();
        $(this.currentValidator.currentForm)
            .off(".validate")
            .removeData("validator")
            .find(".validate-equalTo-blur")
            .off(".validate-equalTo")
            .removeClass("validate-equalTo-blur")
            .find(".validate-lessThan-blur")
            .off(".validate-lessThan")
            .removeClass("validate-lessThan-blur")
            .find(".validate-lessThanEqual-blur")
            .off(".validate-lessThanEqual")
            .removeClass("validate-lessThanEqual-blur")
            .find(".validate-greaterThanEqual-blur")
            .off(".validate-greaterThanEqual")
            .removeClass("validate-greaterThanEqual-blur")
            .find(".validate-greaterThan-blur")
            .off(".validate-greaterThan")
            .removeClass("validate-greaterThan-blur");


    },

    errorPlacementBetter: function (error,element) {
        var find = '\\$';
        var re = new RegExp(find, 'g');
        betterErrorID = ($(error).attr('id')).replace(re, '_');
        $(error).attr('id', betterErrorID);

        element.attr('aria-describedby', ($(error).attr('id') + ' ' + element.attr('aria-describedby')).trim());
        element.attr('aria-invalid', 'true');

        //fix the id with dollarsigns...
        //this is because jquery validator automatically makes the error id the same as the input control name{-error} that it is validating.  
        //then jquery doesn't like ID's with a $ in them.. so we need to fix it before it gets added.  We remove it and add the new on so the
        // event listeners are still attached.
        var prevElementToError = $('#' + betterErrorID).prev();
        $('#' + $(error).attr('id')).remove();
        if (prevElementToError.length>0) {
            error.insertAfter(prevElementToError);
        } else {
            error.insertAfter(element);
        }
        
    },
    //wires up the validator for Beacon Modules
    beaconModuleValidator: function (moduleRules) {

        if (this.currentValidator !== null) {
            this.destroy();
        }
//hijack the existing errorPlacement and run my own after it.
        var f = moduleRules.errorPlacement;
        moduleRules.errorPlacement = function (error, element) {
            if (f) {
                f(error, element);
            }
                Beacon.JqueryValidators.errorPlacementBetter(error, element);
        }
        //we can't use the label type anymore because the control is aria-describedby not label for='inputid' anymore.
        moduleRules.errorElement = "span";
        
        this.currentValidator = $("form").validate(moduleRules);
        var isvalid = $("form").valid();
        if (isvalid) {
            //sometimes I need to do a form submit..

        } else {

            event.preventDefault();
            this.currentValidator.focusInvalid();
        }
    },
    buildDependentRadio: function (dependentLblName, dependentRdoName, controllingRadioUnRequiredValue, controllingRadio) {
        //wire up the onclick event for the controlling radio button to add errors and required *

        $('#' + controllingRadio).click(function () {
            

            var t = (t == null) ? $("#" + dependentLblName + " > abbr") : '<abbr class="important-note" title="required">*</abbr>';  //get the required * element
            if (Beacon.JqueryValidators.isRadioButtonRequired(controllingRadio, controllingRadioUnRequiredValue)) {

                t = (t.html() == undefined) ? '<abbr class="important-note" title="required">*</abbr>' : t;

                $("#" + dependentLblName).append(t);
                //if the dependentRdo Button isn't filled in then throw an error behind it.
                if (!Beacon.JqueryValidators.isRadioButtonRequired(dependentRdoName, "")) {
                    $('#' + dependentRdoName).addClass("error"); //if the controlling radiobutton has the dependsValue then throw an error
                }


            }
            else {
                $('#' + dependentRdoName).removeClass("error");
                $("#" + dependentLblName + " > abbr").remove();
                $('#' + dependentRdoName).removeClass("error");
                $('#' + dependentRdoName + '-error').remove();

                t.remove();
            }
        });
        $('#' + dependentRdoName).click(function () {
              $('#' + dependentRdoName).removeClass("error");
            $('#' + dependentRdoName + '-error').remove();

        });
    },
    isRadioButtonRequired: function (requiredRadioButtonName, unRequiredValue) {
       
        var radioButtonValue = ($('input[name="' + requiredRadioButtonName + '"]:checked').val());
        radioButtonValue = (radioButtonValue == undefined) ? "" : radioButtonValue;
       
        return (radioButtonValue != unRequiredValue);
    },

    isDate: function (txtDate) {
        var currVal = txtDate;
        if (currVal == '')
            return false;
        var rxDatePattern = /^(\d{1,2})(\/|-)(\d{1,2})(\/|-)(\d{4})$/;
        var dtArray = currVal.match(rxDatePattern);

        if (dtArray == null)
            return false;

        dtMonth = dtArray[1];
        dtDay = dtArray[3];
        dtYear = dtArray[5];

        if (dtMonth < 1 || dtMonth > 12)
            return false;
        else if (dtDay < 1 || dtDay > 31)
            return false;
        else if ((dtMonth == 4 || dtMonth == 6 || dtMonth == 9 || dtMonth == 11) && dtDay == 31)
            return false;
        else if (dtMonth == 2) {
            var isleap = (dtYear % 4 == 0 && (dtYear % 100 != 0 || dtYear % 400 == 0));
            if (dtDay > 29 || (dtDay == 29 && !isleap))
                return false;
        }
        return true;
    },

    isEmail: function (value, element) {

        return /^\b[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\b$/.test(value);


    },
    isNumber: function (value, element) {

        return !isNaN(value);
    },
    isPDFFile: function (value, element) {

        return /([a-zA-Z0-9]+\.pdf)$/.test(value);
    }
    ,
    isPhoneNumber: function(value,element){
        validPhone =/^[1-9]\d{2}-\d{3}-\d{4}$/.test(value);
        //console.log("is phone valid?: " + validPhone)
        return validPhone;
    },
    isCityStateZip:function(value,element){
        validCityStateZip =/^\s*[a-zA-Z\s]+,+\s(A[LKSZRAP]|C[AOT]|D[EC]|F[LM]|G[AU]|HI|I[ADL N]|K[SY]|LA|M[ADEHINOPST]|N[CDEHJMVY]|O[HKR]|P[ARW]|RI|S[CD] |T[NX]|UT|V[AIT]|W[AIVY])\x20(?!0{5})\d{5}(-\d{4})?$/.test(value);
       // console.log("is city state zipe valid?: " + validCityStateZip)
        return validCityStateZip;
    },
    zipCode: function (value, element) {
        return /^[0-9]{5}(-\d{4})?$/.test(value) || value === '';
    },

    CLASS_NAME: "Beacon.WCAG"
};



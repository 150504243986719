Beacon.Notifications = {
    _activeId: null,
    _announcementData: null,
    _userId: null,
    me: null,
    ackedCount: 0,
    newAcks: 0,
    init: function (activeId,userId) {
        me = this;
        me._userId = userId;
        me._activeId = activeId
        var updateMinutes = .5;
          //figure out what announcement data is currently in the storage.
         //get the values currently in localStorage.
        _announcementData = Beacon.localStorage.getItem('announcementData') ? Beacon.localStorage.getItem('announcementData') : '{}';
        //Parse it into a json object since localStoarge only stores strings.
        _announcementData = JSON.parse(_announcementData);
        
        //this is a lodash call to see if local storage doesn't have any values yes.... first time load
        if (_.isEmpty(_announcementData)) {
            var refreshTime = Date.now() - (updateMinutes * 60000);
            var refreshTimeISO = new Date(refreshTime).toISOString();
            _announcementData['SignalUpdate'] = {};
            _announcementData['SignalUpdate']['BrowserUpdateTime'] = refreshTimeISO;
            _announcementData['notifications'] = [];
            _announcementData['acknowledgements'] = [];
            _announcementData['trash'] = [];
            _announcementData['SignalUpdate']['UserUpdateChange'] = "<%= Me.AppBase.Security.User.Email %>";

        }

        var currentTime = new Date().toISOString();
        var refreshTimeISO = _announcementData['SignalUpdate']['BrowserUpdateTime'];
        var refreshTime = Date.now() + (updateMinutes * 60000);
        var refreshTimeISO = new Date(refreshTime).toISOString();
        _announcementData['SignalUpdate']['BrowserUpdateTime'] = refreshTimeISO;

        //stringify them and put them in the database.
        Beacon.localStorage.setItem('announcementData', JSON.stringify(_announcementData));
        _announcementData = JSON.parse(Beacon.localStorage.getItem('announcementData'))
        var list = $('#notificationDropDown');
        if (notificationAnnouncementJS.data.length > 0) {
            //var orderedNotifications
            this.updateNotificationList(notificationAnnouncementJS);
        }
        _announcementData['noMessages'] = false;
    

    },
    updateBadge: function (value) {

        $(".notificationbadge").text(value);
        if (value == 0) {
            $(".notificationbadge").hide();
            $(".dropdown-menu").removeClass("unread")
        } else {
            $(".dropdown-menu").addClass("unread")
            $(".notificationbadge").show();
        }
    },
    allTheNotificationsTrashed:function (n) {
        //find out if all the notifications in n are also in the locally stored trashed property.
        var untrashedNotifications = n.data.filter(function (notification) {
            var element = _announcementData['trash'].filter(function (element) { return ((element.notificationID == notification.ID) && (element.UserID == me._userId)) });
            return (element.length == 0);
        });
        return (untrashedNotifications.length==0);
    },
    updateNotificationList: function (n) {
        //update the notifications pane from local storage.
        //read the notifications from local storage.
        var list = $('#notificationDropDown');
        var bottomAnnouncements = new Array();
        n.data.forEach(function (x, i) {

            //trashed
            var element = _announcementData['trash'].filter(function (element) { return ((element.notificationID == x.ID) && (element.UserID == me._userId)) })
            if (element.length > 0) {
                x.trashed = true;
            } else {
                x.trashed = false;
            }

            var trashedClass = x['trashed'] ? 'trashed' : ''
            x['trashedClass'] = trashedClass;
         
            //acknowledgements
            var element = _announcementData['acknowledgements'].filter(function (element) { return ((element.notificationID == x.ID) && (element.UserID == me._userId)) })
            if (element.length > 0) {
                x.acked = true;
            } else {

                if (!x.trashed) {
                    me.newAcks += 1;
                    me.updateBadge(me.newAcks);
                }
                x.acked = false;
            }
            var ackedClass = x['acked'] ? 'read' : 'unread'
            x['ackedClass'] = ackedClass;
        });

        const sorter = function(a, b) {
            if ((a.acked == false) && (b.acked == false)) { return 0 }
            if (a.acked == false) { return -1 }
            if (a.acked == true) { return 1 }
        };
        n.data.sort(sorter);
        list.append(Beacon.Templates.notifications(n));
        //notification-card
        if (n.data.length > 0) {
            //if I trash it and all the messages are trashed notify the users there are no messages, or hide the bell
            $('.notification').show();
            if (this.allTheNotificationsTrashed(n)) {
                
                $("#nomessages").show();
                $('.notification').hide();
            }
            $(".notificationbadge").addClass("badge");
            //this prevents the div from collapsing when there is a link...
            $('ul li .notification-data-text a').on('click', function (e) {
                e.stopPropagation();
            });
            $('.dropdown-submenu div.notification-data ul').on("click", function (e) {
                $(this).toggle();
                $(this).prev('ul').toggle();
                $(this).next('ul').toggle();
                e.stopPropagation();
                e.preventDefault();
            });

            $(".notification-trash").on("click", function (e) {
                var likeSection = $(this).parent();
                var notes = $('.trash', this);
                notes.each(function (idx, r) {
                    var trashElement = $(r).first();
                    var noteId = trashElement.data("trash_id");

                    _announcementData['trash'].push({ 'notificationID': noteId, 'UserID': me._userId });
                    Beacon.localStorage.setItem('announcementData', JSON.stringify(_announcementData));
                    var hasbeenread = ($('[not_id="' + noteId + '"]').hasClass("unread"));
                    $('[not_id="' + noteId + '"]').addClass("trashed");
                    //if I trash it and all the messages are trashed notify the users there are no messages, or hide the bell
                    if (me.allTheNotificationsTrashed(n)) {
                        $("#nomessages").show();
                    }
                    if (hasbeenread) {
                         me.newAcks -= 1;
                         me.updateBadge(me.newAcks);
                    }
                    e.stopPropagation();
                    e.preventDefault();
                });
            });

            $(".notification-like").on("click", function (e) {
                var likeSection = $(this).parent();
                var notes = $('.like-badge', this);
                notes.each(function (idx, r) {
                    var badgeElement = $(r).first();
                    var noteId = badgeElement.data("noteid");

                    Beacon.API.AnnouncementAcknowledgement(me._activeId, noteId, function (value) {

                        if (value.success == "true") {
                            badgeElement.text(value.count);
                        } else {
                            var likeFailure = $('.like-failure', likeSection);
                            likeFailure.text('Registered User Feature');
                            likeFailure.show().delay(5000).fadeOut();
                        }

                    }
                    );
                }
                );

                e.stopPropagation();
                e.preventDefault();
            });

            var acknowlegeAnnouncment = function (e, j) {

                var value = _announcementData['acknowledgements'].filter(function (element) {
                    return ((element.notificationID == parseInt(j.attr('not_id'))) && (element.UserID == me._userId));
                })

                if (value.length > 0) {
                    //it's already acknowledged...do nothing
                } else {
                    $('[not_id="' + j.attr('not_id') + '"]').removeClass("unread");
                    me.newAcks=me.newAcks - 1
                    me.updateBadge(me.newAcks);
                    _announcementData['acknowledgements'].push({ 'notificationID': j.attr('not_id'), 'UserID': me._userId });
                    Beacon.localStorage.setItem('announcementData', JSON.stringify(_announcementData));
                }
                if (e) {
                    e.stopPropagation();
                    e.preventDefault();
                }
            }

            $('.subject-top').on("click", function (e) {

                $(this).next('div').children('ul').toggle();
                var z = $($(this).next('div').first('ul'));
                var b = z.next('ul');
                var v = $('.dropdown-submenu', $(this).next('div'));

                acknowlegeAnnouncment(null, $(v));
                b.toggle();
                e.stopPropagation();
                e.preventDefault();
            });

            $('.dropdown-submenu').on("click", function (e) { acknowlegeAnnouncment(e, $(this)); })
        }
        else {
            //there is no notification to display.
            $('.notification').hide();
        }
    }
    
};
Beacon.FileUploader = {

    ShowUploadDialog: function (url, acceptedFiles, callback, parallelUploads) {
        /*
        * url - upload endpoint (required)
        * callback - null will do a page refresh, otherwise call 'callback' when upload queue has been completed (optional)
        * acceptedFiles - comma-sep list of mimetypes and/or file extensions: image/*,application/pdf,.psd (optional)
        * parallelUploads - optional - default value is 1
        */

        var dzOptions =
        {
            method: "POST",
            url: url,
            timeout: 10 * 60 * 1000, // 10 minutes
            maxFilesize: 1000, // MB
            parallelUploads: parallelUploads || 1,
            addRemoveLinks: false,
            createImageThumbnails: false,
            disablePreviews: false,
            clickable: true,
            chunking: true,
            forceChunking: true,
            chunkSize: 1024 * 1024, // 1MB
            parallelChunkUploads: false,
            retryChunks: false, // seems useful, but backend probably doesn't support this
            retryChunksLimit: 3,
            previewTemplate: Beacon.Templates.DropzoneUpload(),
            acceptedFiles: acceptedFiles,

            init: function () {
                var dz = this;
                this.on("queuecomplete", function (files, response) {
                    if (dz.getRejectedFiles().length === 0) {
                        if (callback) {
                            setTimeout(function () {
                                callback();
                                $("div.modal").modal('hide');
                            }, 1000);
                        } else {
                            setTimeout(function () { window.location.reload(); }, 1000);
                        }
                    }
                });
            }
        };

        var myDzId = "dz_" + (new Date()).valueOf();
        var formHtml = "<div id='" + myDzId + "' class='dropzone dropzone-dialog'></div>";
        Beacon.Dialogs.showWithHtml(null, "Upload files", formHtml, false, "Close", function () { myDz.disable(); $("#" + myDzId).remove(); });
        var myDz = new Dropzone("#" + myDzId, dzOptions);

    },

    CLASS_NAME: 'Beacon.FileUploader'

};


function LoadAsyncContent(data,moduleid) {
  
    var ac = $('.AsyncContent_' + moduleid);  //parent to the rest

    var pu = $('.UserPrompt_' + moduleid);
    var pw = $('#PleaseWait_' + moduleid);
    var er = $('#ErrorMsg_' + moduleid);
    
    //show pleasewait

    pu.hide();
    er.hide();
    pw.show();

    $.ajax({
        url: '/Modules/Application/AsyncRenderer.aspx' + '?QPS=' + mapConfig.QPS,
        type: 'POST',
        data: data,
        timeout: 60000,
        success: function(data) {
            ac.html(data);
        },
        error: function(req, status, errthrown) {
            pw.hide();
            er.show();
        }
    });

    return false;
}


Beacon.PageInitialization = function (isGenericPage) {

    //IE detection
    if (!!window.document.documentMode) {
        if (Beacon.sessionStorage.getItem("IEWarning") != "1") {
            window.location =
                "/InternetExplorer?ReturnUrl="
                + encodeURIComponent(window.location.pathname + window.location.search)
            ;
        }
    }

    $(function () {

        function logException(ex, message) {
            if (ex) {
                var st = printStackTrace({ e: ex });
                var stacktrace = st.join("\n");
                console.error(stacktrace);
                Beacon.API.LogJsError(message, stacktrace);
            }
        }

        try {
            if (!isGenericPage) { Beacon.EULA.Initialize(); }
        } catch (ex) {
            logException(ex, "Beacon.EULA.Initialize failed");
        }

        //Beacon.Tabs.initialize(); -- moved to tabs.ascx so tabs generate without weird flash
        try {
            if (!isGenericPage) { Beacon.ModuleHeader.Initialize(); }
        } catch (ex) {
            logException(ex, "Beacon.ModuleHeader.Initialize failed");
        }

        try {
            if (!isGenericPage) { Beacon.Ping.init(); }
        } catch (ex) {
            logException(ex, "Beacon.Ping.init failed");
        }

        try {
            if (!isGenericPage) { Beacon.LazyLoader.initialize(); }
        } catch (ex) {
            logException(ex, "Beacon.LazyLoader.initialize failed");
        }


        try {
            Beacon.TabularDataSupport.init();
        } catch (ex) {
            logException(ex, "Beacon.TabularDataSupport.init failed");
        }

        try {
            if (!isGenericPage) { Beacon.PhotoEngine.initialize(); }
        } catch (ex) {
            logException(ex, "Beacon.PhotoEngine.initialize failed");
        }

        try {
            if (!isGenericPage) { Beacon.SearchBox.initialize(); }
        } catch (ex) {
            logException(ex, "Beacon.SearchBox.initialize failed");
        }

        try {
            if (!isGenericPage) { Beacon.PrintDialog.onPageLoad(); }
        } catch (ex) {
            logException(ex, "Beacon.PrintDialog.onPageLoad failed");
        }

        try {
            Beacon.WCAG.initialize();
        } catch (ex) {
            logException(ex, "Beacon.WCAG.initialize failed");
        }

        try {
            if (!isGenericPage) { Beacon.JqueryValidators.initialize(); }
        } catch (ex) {
            logException(ex, "Beacon.JqueryValidators.initialize failed");
        }

        try {
            // enable tooltip wrappers 
            $('.tooltip-wrapper').tooltip();
        }
        catch (ex) {
            logException(ex, "wireup tooltips");
        }
    });

};



Beacon.Charts = {

    CaptureTableData: function (ft) {
        var rows = [];
        var tbody = $(ft.table).find("> tbody");
        tbody.find('> tr').each(function () {
            var $row = $(this);

            var rowHeadText = $row.find("th").text();

            var cellValues = [];
            var cellHash = {};

            for (var i in ft.columns) {
                var column = ft.columns[i];
                var value = ft.parse(this.cells[column.sort.match], column);
                cellValues.push(value);
                if (column.name) {
                    cellHash[column.name] = value;
                }
            }

            var row = { 'cells': cellValues, cells2: cellHash, rowHeader: rowHeadText };
            rows.push(row);
            return true;
        })

        var colHeaders = $.map(ft.columns, function (o) { return o.name });
        return { colHeaders: colHeaders, rows: rows };
    },

    // undefined/null for not found
    FindRow: function (ftData, indexOrName) {
        if (typeof indexOrName === 'number') {
            if (indexOrName < 0) {
                indexOrName = ftData.rows.length + indexOrName;
            }
            if (indexOrName >= ftData.rows.length) {
                return undefined;
            }
            return ftData.rows[indexOrName];
        } else {
            return _.find(ftData.rows, { 'rowHeader': indexOrName });
        }
    },

    // -1 for not found
    FindRowIndex: function (ftData, indexOrName) {
        if (typeof indexOrName === 'number') {
            if (indexOrName < 0) {
                indexOrName = ftData.rows.length + indexOrName;
            }
            if (indexOrName >= ftData.rows.length) {
                indexOrName = -1;
            }
            return indexOrName;
        } else {
            return _.findIndex(ftData.rows, { 'rowHeader': indexOrName });
        }
    },

    // -1 for not found
    FindColumnIndex: function (ftData, indexOrName) {
        if (typeof indexOrName === 'number') {
            if (indexOrName < 0) {
                indexOrName = ftData.colHeaders.length + indexOrName;
            }
            if (indexOrName >= ftData.colHeaders.length) {
                indexOrName = -1;
            }
            return indexOrName;
        } else {
            return _.indexOf(ftData.colHeaders, indexOrName);
        }
    },

    GetHorizontalSeries: function (ftData, startColumnIndexOrName, endColumnIndexOrName, rowIndexesOrNames, rowHeadIndexOrName, reverseSeries, colSeriesName) {

        var startCol = Beacon.Charts.FindColumnIndex(ftData, startColumnIndexOrName);
        var endCol = Beacon.Charts.FindColumnIndex(ftData, endColumnIndexOrName);

        // get categories:
        var categories = [];
        if (rowHeadIndexOrName != null) {
            // use row data
            var rowHeadIndex = Beacon.Charts.FindRowIndex(ftData, rowHeadIndexOrName); // x axis categories/values
            for (var i = startCol; i <= endCol; i++) {
                categories.push(ftData.rows[rowHeadIndex].cells[i]);
            }
        } else {
            // use the table column headings
            for (var colIndex = startCol; colIndex <= endCol; colIndex++) {
                categories.push(ftData.colHeaders[colIndex])
            }
        }

        if (reverseSeries) {
            categories.reverse();
        }
        categories.unshift('x-axis');

        // data series names:
        var colSeriesNameIndex;
        if (colSeriesName == null) {
            // default - null - use row header
            colSeriesNameIndex = null;
        } else {
            // find the column to use for series names
            colSeriesNameIndex = Beacon.Charts.FindColumnIndex(ftData, colSeriesName);
        }

        // data cols:
        var dataColumns = [categories];

        for (var i = 0; i < rowIndexesOrNames.length; i++) {
            var rowIndex = rowIndexesOrNames[i];
            var row = Beacon.Charts.FindRow(ftData, rowIndex);

            if (row) {
                var cols = [];

                for (var colIndex = startCol; colIndex <= endCol; colIndex++) {
                    var cell = row.cells[colIndex];
                    var v = Beacon.Charts.ParseValue(cell);
                    cols.push(v);
                }

                if (reverseSeries) {
                    cols.reverse();
                }

                // get data series name
                if (colSeriesNameIndex == null) {
                    cols.unshift(row.rowHeader);
                } else {
                    cols.unshift(row.cells[colSeriesNameIndex]);
                }

                dataColumns.push(cols);
            } else {
                console.warn("Missing row header: " + rowIndex)
            }
        }

        return dataColumns;
    },

    GetVerticalSeries: function (ftData, startRowIndexOrName, endRowIndexOrName, colIndexesOrNames, colHeadIndexOrName, reverseSeries) {

        var startRow = Beacon.Charts.FindRowIndex(ftData, startRowIndexOrName);
        var endRow = Beacon.Charts.FindRowIndex(ftData, endRowIndexOrName);;
        var colHeadIndex = Beacon.Charts.FindColumnIndex(ftData, colHeadIndexOrName); // x axis categories/values

        // get categories:
        var categories = [];
        for (var i = startRow; i <= endRow; i++) {
            categories.push(ftData.rows[i].cells[colHeadIndex]);
        }
        if (reverseSeries) {
            categories.reverse();
        }
        categories.unshift('x-axis');

        // data rows:
        var dataColumns = [categories];

        for (var i = 0; i < colIndexesOrNames.length; i++) {
            var colIndex = colIndexesOrNames[i];

            var col = Beacon.Charts.FindColumnIndex(ftData, colIndex);

            if (col >= 0) {
                var cols = [];

                for (var rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
                    var cell = ftData.rows[rowIndex].cells[col];
                    var v = Beacon.Charts.ParseValue(cell);
                    cols.push(v);
                }
                if (reverseSeries) {
                    cols.reverse();
                }
                cols.unshift(ftData.colHeaders[col]);

                dataColumns.push(cols);
            } else {
                console.warn("Missing column header: " + colIndex)
            }
        }

        return dataColumns;
    },

    ParseValue: function (cell) {
        var val = cell.replace(/[^0-9.\-\(]/g, '').replace(/\(/, '-'); // strip non numerics, and turn parens into negative numbers
        val = parseFloat(val);
        if (isNaN(val)) val = 0;
        return val;
    },

    CleanMissingCategoryData: function (chartData) {

        if (chartData.length === 0) {
            return;
        }

        for (var i = chartData[0].length - 1; i > 0; i--) {
            if (!chartData[0][i]) {
                // no value - nuke the corresponding data to keep c3 happy
                for (var j = 0; j < chartData.length; j++) {
                    chartData[j].splice(i, 1);
                }
            }
        }
    },

    CreateChart: function (divId, colData, type) {



        // types: category | timeseries

        var chartConfig = {
            bindto: divId,
            data: {},
            axis: {
                x: {
                    tick: {},
                    padding: {}
                },
                y: {
                    tick: {
                        format: d3.format("$,.0f")
                    }
                }
            }
        };

        // Configuration reference: https://c3js.org/reference.html
        // d3 number format reference: https://github.com/d3/d3-format

        switch (type) {
            case 'category':
                chartConfig.data.x = 'x-axis';
                chartConfig.data.columns = colData;
                chartConfig.axis.x.type = 'category';
                break;

            case 'timeseries':
                chartConfig.data.x = 'x-axis';
                chartConfig.data.columns = colData;
                chartConfig.data.xFormat = '%m/%d/%Y';
                chartConfig.axis.x.type = 'timeseries';
                chartConfig.axis.x.tick.format = '%m/%d/%Y';
                chartConfig.axis.x.padding.left = 1000 * 3600 * 24 * 365; // 120 days
                chartConfig.axis.x.padding.right = chartConfig.axis.x.padding.left;
                break;

            case 'bar':
                chartConfig.data.type = 'bar';
                chartConfig.data.columns = colData.slice(1);
                chartConfig.axis.x.type = 'category';
                chartConfig.axis.x.categories = colData[0].slice(1);
                break;

            case 'stackedbar':
                chartConfig.data.type = 'bar';
                chartConfig.data.columns = colData.slice(1);
                chartConfig.axis.x.type = 'category';
                chartConfig.axis.x.categories = colData[0].slice(1);
                chartConfig.data.groups = [$.map(colData, function (cd, idx) { return (idx === 0) ? null : cd[0] })];
                break;

            default:
                console.error("Invalid chart type: " + type);

        }

        var chart = c3.generate(chartConfig);

    },



    _loaderPromise: null,

    LoadC3: function (onloadCallback) {

        if (Beacon.Charts._loaderPromise) {
            return Beacon.Charts._loaderPromise;
        }

        var p1 = new $.Deferred();
        var p2 = new $.Deferred();

        // chain the 3 promises
        Beacon.Charts._loaderPromise = $.when(p1, p2).promise();

        // use beacon CDN folder
        var path = Beacon._cdnUrl;

        //c3 css file has been added to lib.css, since its really small

        // but these JS files are pretty big, so we load them on demand
        var d3js = document.createElement('script');
        d3js.onload = function () { p1.resolve(); };
        d3js.src = path + '/js/d3.min.js';
        document.head.appendChild(d3js);

        var c3js = document.createElement('script');
        c3js.onload = function () { p2.resolve(); };
        c3js.src = path + '/js/c3.min.js';
        document.head.appendChild(c3js);

        return Beacon.Charts._loaderPromise;

    },

    InsertChart: function (ft, chartDom, options) {

        Beacon.Charts.LoadC3().then(function () {
            try {
                var ftData = Beacon.Charts.CaptureTableData(ft);
                var tableData;
                switch (options.tableType) {
                    case 'H':
                        tableData = Beacon.Charts.GetHorizontalSeries(ftData, options.dataStart, options.dataEnd, options.dataSeries, options.heading, options.reverseData, options.seriesName);
                        break;
                    case 'V':
                        tableData = Beacon.Charts.GetVerticalSeries(ftData, options.dataStart, options.dataEnd, options.dataSeries, options.heading, options.reverseData);
                        break;
                    default:
                        console.error("Invalid table read mode (H or V): " + options.tableType);
                }
                Beacon.Charts.CleanMissingCategoryData(tableData);
                Beacon.Charts.CreateChart(chartDom, tableData, options.chartType);
            } catch (ex) {
                console.error('Failed to initialize chart for module',options,ex);
            }
        });

    },

    InitChart: function (ft, chartInfo, moduleId) {

        var dataTable = $(ft.table);
        
        var btn = $('<button type="button" class="footable-chart-btn"><span class="icon icon-plus-squared-alt"></span> <span class="chart-toggle-label">Show chart</span></button>'); 

        dataTable.after(btn);

        var divChart = null; // track the div so we can just toggle visibility after is gets created

        var visible = false;
        var shouldShow = IsShown(moduleId);
        var icon = $("span.icon", btn);
        var label = $("span.chart-toggle-label", btn);

        btn.click(function () {
            if (!divChart) {
                // create and show
                CreateAndShow()
                AddToShownList(moduleId);
            } else {
                // after it exists, just toggle visibility
                if (visible) {
                    $(divChart).hide();
                    RemoveFromShownList(moduleId);
                    icon.removeClass("icon-minus-squared-alt").addClass("icon-plus-squared-alt");
                    label.text("Show chart");
                    btn.html();
                    visible = false;
                } else {
                    $(divChart).show();
                    AddToShownList(moduleId);
                    icon.removeClass("icon-plus-squared-alt").addClass("icon-minus-squared-alt");
                    label.text("Hide chart");
                    visible = true;
                }
            }

        });


        if (shouldShow || chartInfo.autoShow) {
            _.defer(function () { // this lets most of the pending layout finish first
                CreateAndShow();
            })
        }

        function CreateAndShow() {
            // create and show
            divChart = document.createElement('div');
            var bodyZoom = $("body").css("zoom");
            if (bodyZoom !== 1.0) {
                // If the body element has a zoom applied, we need to reverse it for the chart.  Not sure why.
                $(divChart).css({ "zoom": 1.0 / bodyZoom });
            }
            //dataTable.after(divChart);
            btn.after(divChart);
            Beacon.Charts.InsertChart(ft, divChart, chartInfo);
            visible = true;
            icon.removeClass("icon-plus-squared-alt").addClass("icon-minus-squared-alt");
            label.text("Hide chart");
        }

        function ReadList() {
            try {
                return JSON.parse(Beacon.localStorage.getItem("CHARTS") || "[]");
            } catch (e) {
                return [];
            }
        }

        function WriteList(l) {
            Beacon.localStorage.setItem("CHARTS", JSON.stringify(l));
        }

        function IsShown(moduleId) {
            return _.indexOf(ReadList(), moduleId) >= 0;
        }

        function RemoveFromShownList(moduleId) {
            var l = ReadList();
            l = _.without(l, moduleId);
            WriteList(l);
        }

        function AddToShownList(moduleId) {
            var l = ReadList();
            l.push(moduleId);
            WriteList(l);
        }
    },

    CLASS_NAME: "Beacon.Charts"

};


(function ($, w, undefined) {
    if (w.footable === undefined || w.foobox === null)
        throw new Error('Please check and make sure footable.js is included in the page and is loaded prior to this script.');

    var defaults = {
        enabled: true
    };

    function BeaconCharts(ftmid) {
        var p = this;
        p.name = 'Footable Beacon Charting Plugin';
        p.init = function (ft) {

            // INIT: 
            $(ft.table).bind({
                'footable_initialized': function (e) {

                    try {
                        // get index of this table in the module
                        var moduleSection = $(e.ft.table).parents(".module-content");
                        var moduleId = parseInt(moduleSection.parent().find(".module-header").attr('moduleid'));

                        var ftTables = moduleSection.find("table.footable");

                        var ftIndex = _.indexOf(ftTables, e.ft.table);
                        if (ftIndex < 0) {
                            return;
                        }

                        // find script template - only 1 per module
                        var chartOptionScript = moduleSection.find("SCRIPT[type='text/x-beacon-chart']");
                        if (chartOptionScript.length === 0) {
                            return;
                        }

                        var chartOption = JSON.parse(chartOptionScript.text());

                        // check each one for matching index
                        chartOption.tableIndex = chartOption.tableIndex || 0;
                        if (chartOption.tableIndex === ftIndex) {
                            // init chart support
                            Beacon.Charts.InitChart(ft, chartOption, moduleId);
                        }
                    } catch (ex) {
                        console.error(ex);
                    }
                }
            });
        };
    }

    w.footable.plugins.register(BeaconCharts, defaults);

})(jQuery, window);
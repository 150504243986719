
Beacon.PhotoEngine = {

    initialize: function () {
        Beacon.PhotoEngine.initializeSketchModule();
        Beacon.PhotoEngine.initializePhotoModule();
        Beacon.PhotoEngine.initializeSummaryPhoto();
        Beacon.PhotoEngine.initializeGenericImagesModule();

    },

    initializeSketchModule: function () {
        $('.sketch-thumbnail img').click(function () {

            var index = $(this).data("index");

            var rsdiv = $('<div class="royalSlider rsDefault"></div>');
            $('body').append(rsdiv);

            // this gets mutated into the DOM needed to power a slideshow - clone it so it will work next time too
            var slides = $("#sketchlist a").clone(true, true);



            var slider = rsdiv.royalSlider({
                startSlideId: index,
                autoScaleSlider: true,
                globalCaption: true,
                globalCaptionInside: true,
                fullscreen: {
                    enabled: true,
                    native: false
                },
                slides: slides
            }).data('royalSlider');

            slider.ev.on('rsExitFullscreen', function () {
                slider.destroy();
                rsdiv.remove();
            });

            slider.enterFullscreen();

            return false;
        });
    },

    initializePhotoModule: function () {
        $('.photo-thumbnail img').click(function () {

            var index = $(this).data("index");

            var rsdiv = $('<div class="royalSlider rsDefault"></div>');
            $('body').append(rsdiv);

            // this gets mutated into the DOM needed to power a slideshow - clone it so it will work next time too
            var slides = $("#photolist a.rsImg").clone(true, true);

            var slider = rsdiv.royalSlider({
                startSlideId: index,
                autoScaleSlider: true,
                globalCaption: true,
                globalCaptionInside: true,
                fullscreen: {
                    enabled: true,
                    native: false
                },
                slides: slides
            }).data('royalSlider');

            slider.ev.on('rsExitFullscreen', function () {
                slider.destroy();
                rsdiv.remove();
            });

            slider.enterFullscreen();

            return false;
        });
    },

    initializeSummaryPhoto: function () {

        // in this instance, we do want to modify the rsdiv and turn it into a slideshow
        var rsdiv = $('.primary-photo-rs');

        if (rsdiv) {
            var slides = $("#photolist a.rsImg").clone(true, true);

            if (slides.length > 0) {

                var ct = slides.length;
                var showBullets = (ct > 1 && ct < 15);

                var slider = rsdiv.royalSlider({
                    autoScaleSlider: true,
                    autoScaleSliderWidth: 256,
                    autoScaleSliderHeight: 191,
                    imageScalePadding: 1,
                    numImagesToPreload: 2,
                    controlNavigation: showBullets ? 'bullets' : 'none',
                    globalCaption: true,
                    //globalCaptionInside: true,
                    //imageScaleMode: 'fit',
                    fadeinLoadedSlide: false,
                    fullscreen: {
                        enabled: true,
                        native: false
                    },
                    slides: slides
                }).data('royalSlider');

                if (showBullets) {
                    // make css adjustment to comment line
                    $('.primary-photo-rs .rsGCaption').css('bottom', '20px');
                } else {
                    $('.primary-photo-rs .rsGCaption').css('bottom', '0');
                }

            }
        }
    },

    initializeGenericImagesModule: function () {
        // for GEN1_Images and GEN1_Images2

        var viewModules = $('.gen-image-viewer');
        viewModules.each(function () {

            var viewModule = $(this);

            $('img', viewModule).click(function () {

                var index = $(this).data("index");

                var rsdiv = $('<div class="royalSlider rsDefault"></div>');
                $('body').append(rsdiv);

                // this gets mutated into the DOM needed to power a slideshow - clone it so it will work next time too
                var slides = $(".gen-image-viewer-list a", viewModule).clone(true, true);

                var slider = rsdiv.royalSlider({
                    startSlideId: index,
                    autoScaleSlider: true,
                    fullscreen: {
                        enabled: true,
                        native: false
                    },
                    slides: slides
                }).data('royalSlider');

                slider.ev.on('rsExitFullscreen', function () {
                    slider.destroy();
                    rsdiv.remove();
                });

                slider.enterFullscreen();

                return false;
            });

        });




    }
};
// the prototypelib was lifted from openlayers... which I think got it from some other project?  I just changed the name to prevent forest fires.

PrototypeLib = {}; 
 
PrototypeLib.Class = function () {
    var len = arguments.length;
    var P = arguments[0];
    var F = arguments[len - 1];

    var C = typeof F.initialize == "function" ?
        F.initialize :
        function () { P.apply(this, arguments); };

    if (len > 1) {
        var newArgs = [C, P].concat(
                Array.prototype.slice.call(arguments).slice(1, len - 1), F);
        PrototypeLib.inherit.apply(null, newArgs);
    } else {
        C.prototype = F;
    }
    return C;
};

PrototypeLib.inherit = function (C, P) {
    var F = function () { };
    F.prototype = P.prototype;
    C.prototype = new F;
    var i, l, o;
    for (i = 2, l = arguments.length; i < l; i++) {
        o = arguments[i];
        if (typeof o === "function") {
            o = o.prototype;
        }
        PrototypeLib.Util.extend(C.prototype, o);
    }
};

PrototypeLib.Util = PrototypeLib.Util || {};
PrototypeLib.Util.extend = function (destination, source) {
    destination = destination || {};
    if (source) {
        for (var property in source) {
            var value = source[property];
            if (value !== undefined) {
                destination[property] = value;
            }
        }

        /**
         * IE doesn't include the toString property when iterating over an object's
         * properties with the for(property in object) syntax.  Explicitly check if
         * the source has its own toString property.
         */

        /*
         * FF/Windows < 2.0.0.13 reports "Illegal operation on WrappedNative
         * prototype object" when calling hawOwnProperty if the source object
         * is an instance of window.Event.
         */

        var sourceIsEvt = typeof window.Event == "function"
                          && source instanceof window.Event;

        if (!sourceIsEvt
           && source.hasOwnProperty && source.hasOwnProperty("toString")) {
            destination.toString = source.toString;
        }
    }
    return destination;
};

// define DataEditor class:

Beacon.DataEditor = PrototypeLib.Class({

    editConfig: null,
    saveButtonDiv: null,
    editItems: null,
    defaultEmail: null,
    newGroupCounter: null,
     
    initialize: function (editConfig, defaultEmail) {
        this.editConfig = editConfig;
        this.defaultEmail = defaultEmail;
        this.newGroupCounter = {};
        this.hookup();
        //this.hookupAddRowButtons();
        this.hookupTemplates($('.editorNewRowTempate'));
    },
     

    hookup: function () {
        var editConfig = this.editConfig;
        var me = this;

        this.editItems = [];

        // create the save button
        var btn = $("<div class='editorSaveButton btn btn-sm pull-left hidden-print'><span class='glyphicon glyphicon-envelope'></span> Submit Changes</div>");
        $('.page-center-pane').prepend(btn);
        btn.click(function () { me.showConfirmation(); });


        this.saveButtonDiv = btn;


        // attach the edit ui to each item
        var nontemplateitems = $('[editKey]');
        nontemplateitems.each(function () {
            if ($(this).parents('.editorNewRowTempate').length == 0) {
                me.hookupEditItem(this, me, editConfig);
            }
            //me.hookupEditItem(this, me, editConfig);
        });

        // process hidden admin areas
        $('.editorHiddenForNormalUsers').removeClass('editorHiddenForNormalUsers');
		$('.editorHiddenForEditUsers').hide();

    },

    redrawContent: function () {
        if (this.isChanged()) {
            //show save button
            this.saveButtonDiv.slideDown();
        } else {
            //hide save button
            this.saveButtonDiv.slideUp();
        }
    },

    isChanged: function () {
        for (var i = 0; i < this.editItems.length; i++) {
            var editItem = this.editItems[i];
            if (editItem.isChanged()) {
                return true;
            }
        }
        return false;
    },

    clear: function () {
        for (var i = 0; i < this.editItems.length; i++) {
            var editItem = this.editItems[i];
            editItem.clear();
        }
    },

    showConfirmation: function () { // formerly had parameters: html, title

        var tmpl = $('#editorTemplate_SendEmailForm');
        var html = tmpl.html();

        $.colorbox({
            title: "", // title,
            open: true,
            html: html,
            width: '500px',
            height: '300px'
        });

        var me = this;
        $('#editorSendButton').click(function () { me.sendData(); });
        $('#editorCancelButton').click(function () { $.colorbox.close(); });
        $('#editorUpdatesSentText').text("");
        $('#editorEmailAddress').val(this.defaultEmail);
    },

    sendData: function () {
        $('#editorButtonSpan').hide();
        $('#editorAjaxImage').show();
        $('#editorUpdatesSentText').text("Sending data to server...");

        //gather data:

        var o = {
            email: $('#editorEmailAddress').val(),
            url: document.location.href,
            keyValue: mapConfig.KeyValue,
            html: $(".page-center-pane").html(),
            values: []
        };

        for (var i = 0; i < this.editItems.length; i++) {
            var editItem = this.editItems[i];
            if (editItem.isChanged()) {

                //make everything into an array
                var value = (editItem.value instanceof Array) ? editItem.value : [editItem.value];

                o.values.push({
                    title: editItem.config.title,
                    value: value,
                    group: editItem.editGroup
                });
            }
        }

        //sent the data!
        Beacon.API.PostDataEdits(o, function () {
            //success
            $('#editorButtonSpan').show();
            $('#editorAjaxImage').hide();
            $('#editorUpdatesSentText').text("Your updates have been sent.");
        }, function () {
            console.dir(arguments);
            //fail
            $('#editorButtonSpan').show();
            $('#editorAjaxImage').hide();
            $('#editorUpdatesSentText').text("Failure to send data to server.");
        },
        this);

    },

    hookupEditItem: function (element, me, editConfig) {
        var span = $(element);
        var editKey = span.attr('editKey');
        var cfg = editConfig[editKey];
        if (cfg) {
            var edititem = new Beacon.DataEditor.EditItem(cfg, editKey, span, me);
            me.editItems.push(edititem);
        } else {
            span.addClass('editorUnknownItem')
                        .attr('title', 'The edit key [' + editKey + '] was not found in the editor configuration.');
        }
    },


    hookupTemplates: function (templates) {
        var editConfig = this.editConfig;
        var me = this;

        templates.each(function () {
            
            var targetElement = $(this); // this is the template we are going to copy

            // if we descend from another template, then don't do anything
            if (targetElement.parents(".editorNewRowTempate").length == 0) { 

                var addbutton = $('<div class="editorAddNewRowButton btn btn-primary"></div>');
                addbutton.html('<span class="glyphicon glyphicon-plus"></span> ' + targetElement.attr('editAddNewButtonText') || "New"); // set button text from attribute, if available

                if (targetElement.is("tr")) {
                    // table row - need to add button after table
                    targetElement.closest("table").after(addbutton);
                } else {
                    // normal div or something - add button after template
                    targetElement.after(addbutton);
                }


                // add click events
                addbutton.click(function () {

                    //clone row
                    var tmpHtml = targetElement.clone();
                    tmpHtml.removeClass('editorNewRowTempate');
                    tmpHtml.addClass('editorNewRow');
                    tmpHtml.hide();

                    me.hookupTemplates($('.editorNewRowTempate',tmpHtml));

                    // add delete button
                    var deleteBtn = $("<span class='editorDeleteTemplate btn btn-primary'></span>");
                    deleteBtn.html('<span class="glyphicon glyphicon-remove"></span> ' + (targetElement.attr('editDeleteButtonText') || "Delete"));

                    deleteBtn.click(function() {
 
                        //remove deleted edit controls
                        var tmpItems = $("[editKey]", tmpHtml);
                        for (var i = 0; i < tmpItems.length; i++) {
                            var tmpItem = tmpItems[i];
                            var newEditItems = $(me.editItems).filter(function(j) {
                                var editItem = me.editItems[j];

                                if (editItem.originalElement[0] === tmpItem) {
                                    return false;
                                } else {
                                    return true;
                                }
                            });
                            me.editItems = newEditItems;
                        }

                        deleteBtn.remove();
                        tmpHtml.remove();

                        me.redrawContent();
                    });
                    
                    if (tmpHtml.is("tr")) {
                        // table row - need to add button into 1st cell
                        deleteBtn.attr("title", "Delete this row");
                        var td = $("<td></td>");
                        $(tmpHtml).append(td.append(deleteBtn));
                    } else {
                        // normal div or something - add button after template
                        deleteBtn.attr("title", "Delete this new item");
                        $(tmpHtml).prepend(deleteBtn);
                    }

                    // insert new item right above template
                    targetElement.before(tmpHtml);

                    tmpHtml.fadeIn();

                    // set up new group id
                    var tmpGroupName = tmpHtml.attr('editGroup');
                    if (!tmpGroupName) {
                        tmpGroupName = "New item";
                    }

                    var groupCounter = me.newGroupCounter[tmpGroupName];
                    if (!groupCounter) {
                        groupCounter = 0;
                    }
                    groupCounter++;
                    me.newGroupCounter[tmpGroupName] = groupCounter;

                    // wire up events
                    $('[editKey]', tmpHtml).each(function () {
                        if ( $(this).parents(".editorNewRowTempate").length == 0) { 
                            // poke in new edit group or append group name
                            var itemGroupName = $(this).attr("editGroup");
                            if (itemGroupName) {
                                $(this).attr("editGroup", tmpGroupName + ' ' + groupCounter + ' ' + itemGroupName);
                            } else {
                                $(this).attr("editGroup", tmpGroupName + ' ' + groupCounter);
                            }
                            me.hookupEditItem(this, me, editConfig);
                        }
                    });


                   
                });

            }

        });

    },

  
    CLASS_NAME: "Beacon.DataEditor"
});



Beacon.DataEditor.EditItem = PrototypeLib.Class({

    config: null,
    editKey: null,
    editGroup: null,

    dataEditor: null, //parent editor object

    originalElement: null, //jquery object
    originalText: null,
    containerDiv: null,    //jquery object
    editButtonDiv: null,   //jquery object
    deleteButtonDiv: null,
    isDeleted: false,


    value: null, // null (no input) | string (single value input) | [] (multi value inputs)
    keep: false, // set to true when user wants to keep original value

    html: null, // string - template for ui

    initialize: function (config, editKey, element, editor) {
        this.config = config;
        this.editKey = editKey,
        this.originalElement = element;
        this.dataEditor = editor;
        this.isDeleted = false;

        this.hookup();
    },

    hookup: function () {

        // make sure template exists
        if (!this.isValidTemplate()) {
            this.originalElement.addClass('editorUnknownItem')
			                    .attr('title', 'Invalid editor template specified [' + this.config.mode + '] for edit key [' + this.editKey + ']');
            return; //don't configure anything else
        }

        // get template
        var tmpl = $('#editorTemplate_' + this.config.mode);
        this.html = tmpl.html();
        this.valueTemplate = tmpl.attr('valuetemplate');

        // original value
        this.originalElement.addClass('editorOriginalItem');
        this.originalText = this.originalElement.text() || '(blank)';
        this.editGroup = this.originalElement.attr('editGroup');

        // create edit container
        this.containerDiv = $("<div class='editorUpdatedContainer'></div>");
        this.originalElement.after(this.containerDiv);

        // create edit/delete buttons
        this.editButtonDiv = $("<div class='editorEditButton'></div>");
        this.deleteButtonDiv = $("<div class='editorDeleteItemButton' title='Remove item'></div>");
        this.originalElement.before(this.editButtonDiv);
        //this.editButtonDiv.addClass(this.config.multiItem ? 'editorEditButtonAppend' : 'editorEditButtonUpdate');
        this.editButtonDiv.addClass(this.config.multiItem ? 'glyphicon glyphicon-plus' : 'glyphicon glyphicon-pencil');
        this.editButtonDiv.attr('title', this.config.multiItem ? 'Add an update to this value' : 'Update this value');

        

        // wire up events
        var me = this; // useful for jQuery event functions using different 'this' context

        this.deleteButtonDiv.click(function () {
            me.originalElement.addClass('editorOriginalItemChanged');
            me.keep = false;
            if (me.config.multiItem) {
                if (!me.value) {
                    me.value = ['DELETE: ' + me.originalText];
                }
            } else {
                if (!me.value) {
                    me.value = "DELETE: " + me.originalText;
                }
            }           
            me.redrawContent();
        });

        this.originalElement
		            .hover(function () { $(this).addClass('editorOriginalItemHover'); },
			               function () { $(this).removeClass('editorOriginalItemHover'); })
		            .click(function () { me.editItemClick(); });

        this.editButtonDiv
		            .hover(function () { $(this).addClass('editorEditButtonHover'); },
			               function () { $(this).removeClass('editorEditButtonHover'); })
		            .click(function () { me.editItemClick(); });

        this.redrawContent();
    },

    editItemClick: function () {

        // poor man's template engine:
        var html = this.renderTemplate(this.config, this.html);
        html = html.replace('${originalText}', this.originalText);

        // display overlay
        $.colorbox({
            title: this.config.title,
            open: true,
            html: html,
            width: '580px',
            height: '500px'
        });


        //set initial values - specified default, unless there was a previous value (for single items)
        var theDefaultValue = (this.config.multiItem ? null : this.value) || this.config.defaultValue;
        if (this.config.multiItem) {
            $('#editorKeepOriginalValue').prop("checked", true);
        } else {
            $('#divKOV').hide();
        }
        

        switch (this.config.mode) {

            case 'number':
                $("#editorTemplate_textbox").val(theDefaultValue);
                this.setupNumericInput($("#editorTemplate_textbox"), $('#editorSaveButton'));
                break;

            case 'text':
                $("#editorTemplate_textbox").val(theDefaultValue);
                this.setupTextInput($("#editorTemplate_textbox"), $('#editorSaveButton'));
                break;

            case 'largetext':
                $("#editorTemplate_textbox").val(theDefaultValue);
                break;

            case 'dropdownlargetext':
                var ddl = $("#editorTemplate_codeDdl");
                this.setupDropdownList(ddl, this.config.values, theDefaultValue, true);
                $("#editorTemplate_textbox").val(theDefaultValue);
                ddl.change(function () {
                    var txt = $("#editorTemplate_textbox").val();
                    if (ddl.val()) {
                        $("#editorTemplate_textbox").val(txt + ddl.val() + " ");
                        ddl.val("");
                    }
                });
                break;

            case 'pickone':
                var ul = $(".editorPickOne");
                this.setupPickList(ul, this.config.values, null, false);
                break;

            case 'pickmany':
                var ul = $(".editorPickOne");
                this.setupPickList(ul, this.config.values, null, true, theDefaultValue);
                break;
            case 'pickmanywithqty':
                var ul = $(".editorPickOne");
                this.setupPickList(ul, this.config.values, null, true, theDefaultValue, true);
                break;
            case 'dropdownlistpickmany':
                var ddl = $("#editorTemplate_ddl");
                var ul = $(".editorPickOne");
                this.setupDropdownList(ddl, this.config.values, theDefaultValue, false);
                this.setupPickList(ul, this.config.values2, null, true, this.config.defaultValue2);
                break;

            case 'dropdownlist':
                var ddl = $("#editorTemplate_ddl");
                this.setupDropdownList(ddl, this.config.values, theDefaultValue, false);
                break;

            case 'dropdownlistquantity':
                var ddl = $("#editorTemplate_ddl");
                this.setupDropdownList(ddl, this.config.values, theDefaultValue, false);
                this.setupNumericInput($("#editorTemplate_textbox"), $('#editorSaveButton'));
                break;

            case 'dropdownlisttext':
                var ddl = $("#editorTemplate_ddl");
                this.setupDropdownList(ddl, this.config.values, theDefaultValue, false);
                this.setupTextInput($("#editorTemplate_textbox"), $('#editorSaveButton'));
                break;

            case 'doubledropdownlist':
                var ddl = $("#editorTemplate_ddl");
                this.setupDropdownList(ddl, this.config.values, theDefaultValue, false);
                var ddl2 = $("#editorTemplate_ddl2");
                this.setupDropdownList(ddl2, this.config.values2, this.config.defaultValue2, false);
                break;

            case 'doubledropdownlistquantity':
                var ddl = $("#editorTemplate_ddl");
                this.setupDropdownList(ddl, this.config.values, theDefaultValue, false);
                var ddl2 = $("#editorTemplate_ddl2");
                this.setupDropdownList(ddl2, this.config.values2, this.config.defaultValue2, false);
                this.setupNumericInput($("#editorTemplate_textbox"), $('#editorSaveButton'));
                break;

            case 'tripledropdownlist':
                var ddl = $("#editorTemplate_ddl");
                this.setupDropdownList(ddl, this.config.values, theDefaultValue, false);
                var ddl2 = $("#editorTemplate_ddl2");
                this.setupDropdownList(ddl2, this.config.values2, this.config.defaultValue2, false);
                var ddl3 = $("#editorTemplate_ddl3");
                this.setupDropdownList(ddl3, this.config.values3, this.config.defaultValue3, false);
                break;

            case 'dimensions':
                $("#editorTemplate_textbox1").val(theDefaultValue);
                $("#editorTemplate_textbox2").val(theDefaultValue);
                this.setupTextInput($("#editorTemplate_textbox1"), $('#editorSaveButton'));
                this.setupTextInput($("#editorTemplate_textbox2"), $('#editorSaveButton'));
                break;

            case 'dimensionsheight':
                this.setupTextInput($("#editorTemplate_textbox1"), $('#editorSaveButton'));
                this.setupTextInput($("#editorTemplate_textbox2"), $('#editorSaveButton'));
                this.setupTextInput($("#editorTemplate_textbox3"), $('#editorSaveButton'));
                break;

            case 'pickonewithdimensions':
                var ul = $(".editorPickOne");
                this.setupPickList(ul, this.config.values, null, true);
                this.setupTextInput($("#editorTemplate_textbox1"), $('#editorSaveButton'));
                this.setupTextInput($("#editorTemplate_textbox2"), $('#editorSaveButton'));
                break;

            case 'pickonewithdimensionsyear':
                var ul = $(".editorPickOne");
                this.setupPickList(ul, this.config.values, null, true);
                this.setupTextInput($("#editorTemplate_textbox1"), $('#editorSaveButton'));
                this.setupTextInput($("#editorTemplate_textbox2"), $('#editorSaveButton'));
                this.setupTextInput($("#editorTemplate_textbox3"), $('#editorSaveButton'));
                break;

        }

        //hook up events - connects to all objects with the editorSaveButton class
        this.setupSaveButton($("#editorSaveButton"));

    },

    setupSaveButton: function (btn) {
        var me = this;
        if (this.config.multiItem) {
            btn.attr('value', 'Add Item');
        } else {
            btn.attr('value', 'Update');
        }
        btn.click(function () {
            me.onSaveButtonClick();
        });
    },

    setupDropdownList: function (ddl, values, selectedValue, showBlankItem) {
        if (showBlankItem) {
            var opt = $("<option></option>");
            ddl.append(opt);
        }
        for (var i = 0; i < values.length; i++) {
            var opt = $("<option></option>");
            if (values[i] == selectedValue) {
                opt.attr('selected', 'selected');
            }
            opt.text(values[i]);
            ddl.append(opt);
        }
    },

    setupPickList: function (ul, values, selectedValue, toggleMode, theDefaultValue,showItemQty) {
        var defs = [];
        if (theDefaultValue) {
            defs = theDefaultValue.split("; ");
        }

        for (var i = 0; i < values.length; i++) {
            var li = null;
            if (showItemQty) {
                li = $("<li><span></span><div class='editorItemSelected'>Qty: <input type='number' class='itemQty' style='width: 30px' /></div></li>");
            }
            else {
                li = $("<li><span></span></li>");
            }
            
            li.children('span').text(values[i]);
            if (values[i] == selectedValue) {
                li.child('span').addClass('editorPickOneDefault');
            }
            if ($.inArray(values[i], defs) > -1) {
                li.child('span').addClass('editorPickOneSelect');
            }
            ul.append(li);
        };

        var me = this;

        $("LI", ul)
            .click(function () {
                if (toggleMode) {
                    $(this).toggleClass('editorPickOneSelect');
                } else {
                    me.onSaveButtonClick($(this).text());
                }
            })
            .hover(function () { $(this).addClass('editorPickOneHover'); },
                    function () { $(this).removeClass('editorPickOneHover'); });

        $("LI .itemQty", ul)
            .click(function (e) {
                if (e && e.stopPropagation) {
                    e.stopPropagation();
                }
            });
    },

    setupNumericInput: function (element, saveButton) {
        element.keypress(function (event) {
            if (($.inArray(event.keyCode, [8, 35, 36, 37, 39]) == -1) &&
		           ($.inArray(event.which, [45, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57]) == -1)) {
                event.preventDefault();
            }
            if (event.which == 13) {
                saveButton.click();
            }
        });
    },

    setupTextInput: function (element, saveButton) {
        if (event.which == 13) {
            saveButton.click();
        }
    },

    onSaveButtonClick: function (value) {

        var input1, input2, input3, input4;

        switch (this.config.mode) {

            case 'number':
                input1 = $("#editorTemplate_textbox").val();
                if (!input1) { return; }
                break;

            case 'largetext':
                input1 = $("#editorTemplate_textbox").val();
                if (!input1) { return; }
                break;

            case 'dropdownlargetext':
                input1 = $("#editorTemplate_textbox").val();
                if (!input1) { return; }
                break;

            case 'text':
                input1 = $("#editorTemplate_textbox").val();
                if (!input1) { return; }
                break;

            case 'pickone':
                input1 = value;
                break;

            case 'pickmany':
                input1 = $('.editorPickOneSelect').map(function () { return $(this).text(); }).toArray().join("; ");
                break;
            case 'pickmanywithqty':
                input1 = $('.editorPickOneSelect SPAN').map(function () { return $(this).text(); }).toArray().join("; ");
                input2 = $('.editorPickOneSelect .itemQty').map(function () { return $(this).val(); }).toArray().join("; ");

                var input1Items = input1.split(';');
                var input2Items = input2.split(';');
                var formattedInput = '';

                for (var x = 0; x < input1Items.length; x++) {
                    if (input1Items[x].trim() != '' && input2Items[x].trim() === '') {
                        return false;
                    }
                    else {
                        formattedInput += input1Items[x].trim() + ' (' + input2Items[x].trim() + ')' + (x < input1Items.length ? '; ' : '');
                    }
                }

                input1 = formattedInput;
                break;

            case 'dropdownlistpickmany':
                input1 = $("#editorTemplate_ddl").val();
                input2 = $('.editorPickOneSelect').map(function () { return $(this).text(); }).toArray().join(", ");
                if (!input1) { return; }
                if (!input2) { return; }
                break;

            case 'dropdownlist':
                input1 = $("#editorTemplate_ddl").val();
                if (!input1) { return; }
                break;

            case 'dropdownlistquantity':
                input1 = $("#editorTemplate_ddl").val();
                input2 = $("#editorTemplate_textbox").val();
                if (!input1) { return; }
                if (this.config.optionalQtyValues) {
                    if (this.config.optionalQtyValues.indexOf(input1) === -1) {
                        if (!input2) { return; }
                    }
                }
                else {
                    if (!input2) { return; }
                }
                break;

            case 'dropdownlisttext':
                input1 = $("#editorTemplate_ddl").val();
                input2 = $("#editorTemplate_textbox").val();
                if (!input1) { return; }
                if (!input2) { return; }
                break;

            case 'doubledropdownlist':
                input1 = $("#editorTemplate_ddl").val();
                input2 = $("#editorTemplate_ddl2").val();
                if (!input1) { return; }
                if (!input2) { return; }
                break;

            case 'doubledropdownlistquantity':
                input1 = $("#editorTemplate_ddl").val();
                input2 = $("#editorTemplate_ddl2").val();
                input3 = $("#editorTemplate_textbox").val();
                if (!input1) { return; }
                if (!input2) { return; }
                if (!input3) { return; }
                break;

            case 'tripledropdownlist':
                input1 = $("#editorTemplate_ddl").val();
                input2 = $("#editorTemplate_ddl2").val();
                input3 = $("#editorTemplate_ddl3").val();
                if (!input1) { return; }
                if (!input2) { return; }
                if (!input3) { return; }
                break;

            case 'dimensions':
                input1 = $("#editorTemplate_textbox1").val();
                input2 = $("#editorTemplate_textbox2").val();
                if (!input1) { return; }
                if (!input2) { return; }
                break;
            case 'dimensionsheight':
                input1 = $("#editorTemplate_textbox1").val();
                input2 = $("#editorTemplate_textbox2").val();
                input3 = $("#editorTemplate_textbox3").val();
                if (!input1) { return; }
                if (!input2) { return; }
                if (!input3) { return; }
                break;
            case 'pickonewithdimensions':
                input1 = $('.editorPickOneSelect').map(function () { return $(this).text(); }).toArray().join("; ");
                input2 = $("#editorTemplate_textbox1").val();
                input3 = $("#editorTemplate_textbox2").val();
                if (!input1) { return; }
                if (!input2) { return; }
                if (!input3) { return; }
                break;
            case 'pickonewithdimensionsyear':
                input1 = $('.editorPickOneSelect').map(function () { return $(this).text(); }).toArray().join("; ");
                input2 = $("#editorTemplate_textbox1").val();
                input3 = $("#editorTemplate_textbox2").val();
                input4 = $("#editorTemplate_textbox3").val();
                if (!input1) { return; }
                if (!input2) { return; }
                if (!input3) { return; }
                if (!input4) { return; }
                break;
        }

        var keepOrig = $('#editorKeepOriginalValue').prop("checked");

        if (keepOrig) {
            this.keep = true;
        } else {
            this.keep = false;
        }

        $.colorbox.close();

        var formattedInput = this.config.valueTemplate ?
            this.renderTemplate({ value: input1, value2: input2, value3: input3, value4: input4 }, this.config.valueTemplate)
            : input1;

        if (this.config.multiItem) {
            // add new value:
            if (!this.value) { this.value = []; }
            if (this.keep) {
                this.value.push('ADD: ' + formattedInput);
            } else {
                if (!this.isDeleted) {
                    //this.value.push('DELETE: ' + this.originalText);
                    this.isDeleted = true;
                }
                this.value.push('UPDATE: ' + formattedInput);
            }
            
            
        }else{
            if (formattedInput) {
            //set new value
            this.value = formattedInput;
            } else {
                this.value = 'DELETE: ' + this.originalText;
        }

        }

        this.redrawContent();
    },

    redrawContent: function () {
        this.containerDiv.empty();

        if (this.isChanged()) {

            // make everything into an array
            var values = this.config.multiItem ? this.value : [this.value];

            this.containerDiv.show();

            for (var i = 0; i < values.length; i++) {

                var btn = $("<div class='editorDeleteItemButton' title='Remove item'></div>");
                btn.attr('index', i);
                this.containerDiv.append(btn);

                var div = $("<div class='editorUpdatedItem'></div>");
                div.html(values[i]);
                this.containerDiv.append(div);

                var me = this;

                btn.click(function () {
                    if (me.config.multiItem) {
                        var idx = $(this).attr('index');
                        me.removeOneItem(idx);
                    } else {
                        me.clear();
                    }
                });

                btn.hover(function () { $(this).addClass('editorDeleteItemButtonHover'); },
			                  function () { $(this).removeClass('editorDeleteItemButtonHover'); });

                this.containerDiv.append($("<div style='clear:both'></div>"));
            }

        }else{
            this.containerDiv.hide();
        }


        // cross out original value if new data is present
        //alert(this.keep);
        if (this.isChanged() && !this.keep) {
            this.originalElement.addClass('editorOriginalItemChanged');
        } else {
            this.originalElement.removeClass('editorOriginalItemChanged');
        }

        this.dataEditor.redrawContent();

    },

    isChanged: function () {
        if (this.config.multiItem) {
            return (this.value && this.value.length > 0);
        } else {
            return (this.value) ? true : false;
        }
    },

    clear: function () {
        this.value = null;
        this.redrawContent();
    },

    removeOneItem: function (index) {
        this.value.splice(index, 1);
        this.redrawContent();
    },

    isValidTemplate: function () {
        return $('#editorTemplate_' + this.config.mode).length > 0;
    },

    renderTemplate: function (obj, template) {
        var output = template;
        for (var prop in obj) {
            output = output.replace('${' + prop + '}', obj[prop]);
        }
        return output;
    },

    CLASS_NAME: "Beacon.DataEditor.EditItem"
});



// Init top level Beacon object
Beacon = {};
Beacon.Control = {};
Beacon.Strategy = {};
Beacon.Layer = {};
Beacon.Popup = {};
Beacon.Handler = {};
Beacon.Protocol = {};
Beacon.Tile = {};
Beacon.Templates = {};
Beacon.Account = {};
Beacon.MapJS = {};

// define console so debugging doesnt break ie
if (!window.console) {
    window.console = {
        info: function () { },
        warn: function () { },
        error: function () { }
    }
}


// If a script needs access to the path used for resource loading, Beacon._cdnUrl gets set here:
//    This would be the client side way to get the foldername like the server side code does in `VersionedAssetOptimization`

(function () {
    try {
        var scripts = document.getElementsByTagName("script");
        var script = scripts[scripts.length - 1].src;
        var rx = /\/cdn\/([0-9a-f]{8})\//i;
        var u = script.match(rx)[1];
        Beacon._cdnUrl = '/cdn/' + u;
    } catch (e) {
        Beacon._cdnUrl = '/cdn/00000000'; // something is busted
    }
})();


Beacon.TabularDataSupport = {

    init: function () {

        Beacon.TabularDataSupport.configureFootableDefaults();

        // Look for a stored search intent and flag the corrisponding column to be sorted...AL
        var searchIntentAttribute = Beacon.sessionStorage["SearchIntent"];
        var searchIntent = "";
        var searchIntentOrder = "ascending";

        if (searchIntentAttribute && searchIntentAttribute.indexOf('|') > -1) {
            var searchIntentParts = searchIntentAttribute.split('|');
            searchIntent = searchIntentParts[0];
            searchIntentOrder = searchIntentParts[1].trim().toLowerCase();
        }
        else {
            searchIntent = searchIntentAttribute; // No sort order was specified - so the attribute is the full column name...AL
        }

        if (searchIntent) {
            var searchHeaders = $("th[data-sort-name]");
            
            for (var i = 0; i < searchHeaders.length; i++) {
                if (searchHeaders[i].getAttribute("data-sort-name").toString().trim().toLowerCase() == searchIntent.toString().trim().toLowerCase()) {
                    searchHeaders[i].setAttribute("data-sort-initial", searchIntentOrder);
                    searchHeaders[i].setAttribute("aria-sort", searchIntentOrder)
                    // queue the fixup function if we've messed with the initial sort
                    setTimeout(function () {
                        Beacon.LazyLoader.fixupAfterGridSort();
                    }, 1);
                }
            }
        }

        //eliminate this column that we don't want to be shown
        $('.footable').find(window.footable.options.columnDataSelector).each(function () {
            var name = $(this).data('name') || $.trim($(this).text());

            if (name === 'B_RawParcelID') {
                $(this).data('ignore', true);
            }
        });

        // wire up footable support
        $('.footable').footable({});

        // Provide unique labels for each of the expand/collapse buttons
        $('.footable').each(function (idx, element) {
            Beacon.TabularDataSupport.labelExpandCollapseButtons(element);
        })

        /**** Wire up the "Set Reults" column to the onlick of ordering so that the server always knows the order of the parcels (to facilitate the next/previous buttons)...AL ****/
        var table = $(".footable")[0];

        var B_RawParcelID_Idx = -1;

        if (table) {
            for (var i = 0, c = table.rows[0].cells.length; i < c; i++) {
                if (table.rows[0].cells[i].innerText == "B_RawParcelID") {
                    B_RawParcelID_Idx = i;
                    break;
                }
            }
        }

        if (B_RawParcelID_Idx > -1) {
            $('.footable').bind({
                'footable_sorted': function (e) {
                    Beacon.TabularDataSupport.submitOrderedResultsToServer(this, B_RawParcelID_Idx);
                }
            });

            Beacon.TabularDataSupport.submitOrderedResultsToServer(table, B_RawParcelID_Idx);
        }
        /****/

        //remove the makeshift style attributes
        $('.responsive-table td').removeAttr('style');
        //if an align attribute is needed, please use CSS, and don't write it into the markup
        $('.responsive-table td').removeAttr('align');
        $('.responsive-table td').removeAttr('width');

        function checkboxToggle() {
            var row = $(this).closest('tr');

            if (this.checked) {
                row.removeClass('hidden-criterion');
            } else {
                row.addClass('hidden-criterion');
            }
        }

        $('.responsive-table').each(function () {
            //figure out which column the checkbox is in, if any
            var checkboxCol = null;
            for (var colNum = 0; colNum < 5; colNum++) {
                if ($(this).hasClass('column-' + colNum + '-checkbox')) {
                    checkboxCol = colNum;
                    break;
                }
            }
            if (checkboxCol === null) {
                // if there is no checkbox column, there's nothing to do
                return;
            }

            checkboxCol++; //CSS is base 1, so increment

            //initialize the visibility
            $(this)
                .find('tr > :nth-child(' + checkboxCol + ') input[type=checkbox]')
                .each(checkboxToggle)
                ;

            //register the toggle function
            $(this)
                .find('tr > :nth-child(' + checkboxCol + ') input[type=checkbox]')
                .change(checkboxToggle)
                ;

            //remove the 'use' label if there isn't actually a checkbox in that row
            //(sometimes there are empty rows for dubious reasons)
            $(this).find('tr > :nth-child(' + checkboxCol + ')').each(function () {
                if ($(this).find('input[type=checkbox]').length < 1) {
                    $(this).addClass('no-checkbox');
                }
            })
        });
    },
    labelExpandCollapseButtons: function (tbl) {
        const uniqueColIdxs = Beacon.TabularDataSupport.getUniqueColumnIdxes(tbl);

        $(tbl).find('tbody > tr').each((idx, row) => {
            let titleText = 'Toggle additional data for';
            for (let col of uniqueColIdxs) {
                const cellText = $($(row).find('td,th')[col.colIdx]).text().trim();
                titleText += ` ${col.headerText} ${cellText}`;
            }

            const toggleButton = $(row).find('a.footable-toggle');
            $(toggleButton).attr('title', titleText);
        });
    },

    getUniqueColumnIdxes: function (tbl) {
        // see if the table has the attribute data-unique-columns
        const uniqueColAttr = $(tbl).attr('data-unique-columns');
        if (uniqueColAttr && uniqueColAttr !== '') {
            const attrCols = Beacon.TabularDataSupport.useUniqueColsAttr(tbl, uniqueColAttr);
            if (attrCols.length > 0) {
                return attrCols;
            }
        }

        // otherwise, see which row is supposed to be the row header
        const rowScopeCols = Beacon.TabularDataSupport.useRowScope(tbl);
        if (rowScopeCols.length > 0) {
            return rowScopeCols;
        }

        // and, if all else fails, just look for the first column that has text
        const firstHeaderFound = Beacon.TabularDataSupport.useFirstRow(tbl);
        if (firstHeaderFound.length > 0) {
            return firstHeaderFound;
        }

        return null;
    },

    useUniqueColsAttr: function (tbl, uniqueColList) {
        const headerRow = Beacon.TabularDataSupport.getHeaderRow(tbl);

        let uniqueCols = [];
        uniqueColList.split(',').forEach((val) => {
            const num = parseInt(val);
            // make sure that it is actually a valid index
            if (num !== null && num >= 0 && num < $(headerRow).find('td,th').length) {
                const headerCell = $(headerRow).find('td,th')[num];
                uniqueCols.push({
                    colIdx: num,
                    headerText: $(headerCell).text().trim()
                });
            }
        });
        return uniqueCols;
    },
    useRowScope: function (tbl) {
        let uniqueCols = [];

        /* GridViewBeaconizer will set scope="row" on data cells in the column on which
         * RowHeaderIndex is set. But it will only set this on data cells, so we have to
         * find the first data row, and find the index of the cell that is marked in
         * this way.
         */

        const firstDataRow = Beacon.TabularDataSupport.getFirstDataRow(tbl);
        const dataHeader = $(firstDataRow).find('td[scope="row"]');
        const headerIdx = $(firstDataRow.find('td,th')).index(dataHeader);
        const columnHeader = $(tbl).find(`thead > tr:nth-child(${headerIdx + 1})`);
        if (columnHeader.length > 0) {
            uniqueCols.push({
                colIdx: headerIdx,
                headerText: $(columnHeader).text().trim()
            });
        }

        return uniqueCols;
    },
    useFirstRow: function (tbl) {
        let uniqueCols = [];

        // just find the first header cell that contains text

        const headerRow = Beacon.TabularDataSupport.getHeaderRow(tbl);
        $(headerRow).find('td,th').each((idx, element) => {
            if ($(element).text().trim() !== '') {
                uniqueCols.push({
                    colIdx: idx,
                    headerText: $(element).text().trim()
                });
                return false;
            }
        });

        return uniqueCols;
    },

    getHeaderRow: function (tbl) {
        return $(tbl).find('thead > tr:last-child');
    },
    getFirstDataRow: function (tbl) {
        return $(tbl).find('tbody > tr:first-child');
    },

    submitOrderedResultsToServer: function (table, B_RawParcelID_Idx) {

        // Get a list of the parcel ids from the table (in row order) and post that list back to the set results endpoint...AL
        var orderedPIDs = [];
        if (table && table.rows) {
            for (var i = 1, r = table.rows.length; i < r; i++) {
                var innerText = table.rows[i].cells[B_RawParcelID_Idx].innerText;
                if (innerText) orderedPIDs.push(innerText.trim());
            }

            Beacon.API.SetOrderedResults(mapConfig.LayerId, orderedPIDs);
        }
    },
    configureFootableDefaults: function () {

        footable.options.breakpoints = {
            'footable-small': Beacon.Variables.smallLayoutWidth,
            'footable-medium': Beacon.Variables.mediumLayoutWidth
        };

        footable.options.delay = 250;

        footable.options.parsers.address = function (cell) { // This will cause sorting to sort the 1st part of the address numerically, the rest by name
            var val = $(cell).text();

            //find ##### name...
            var a = val.match(/^(\d+)\s+(.*)$/);

            if (a) {
                var num = ("0000000000" + a[1]).slice(-10);
                var street = a[2];
                //return num + ' ' + a[2];
                return street + ' ' + num;
            }
            else {
                return val;
            }
        };

        // This isolates the first date in a multi-line cell
        footable.options.parsers.multilineDate = function (cell) {
            var val = $(cell).html();
            var parts = val.split("<br>");
            return Date.parse(parts[0]);
        };

        // This isolates the first numeric in a multi-line cell
        footable.options.parsers.multilineNumeric = function (cell) {
            var val = $(cell).html();
            var parts = val.split("<br>");
            var sortPart = parts[0];
            if (sortPart == '&nbsp;') {
                sortPart = '0'
            }
            return parseFloat(sortPart.replace(/[^\d.]/g, ''));
        };

        footable.options.parsers.plss = function (cell) {
            var val = $(cell).text();

            //find #-#-# type of thing...
            var a = val.match(/^(\d+)-(\d+)-(\d+)$/);

            if (a) {
                var num = ("0000" + a[1]).slice(-4);
                return ("0000" + a[1]).slice(-4) + '-' + ("0000" + a[2]).slice(-4) + '-' + ("0000" + a[3]).slice(-4);
            }
            else {
                return val;
            }
        };

        footable.options.parsers.date = function (cell) {
            var val = $(cell).text();
            return Date.parse(val);
        };
    }

};


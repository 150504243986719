
// EULA pop over
Beacon.EULA = {

    _affirmativeValue: "v3 accepted",

    Show: function (html) {

        Beacon.Dialogs.showWithHtml(
            null,
            "Terms and Conditions",
            html,
            true,
            "Agree", function () {
                if (mapConfig.EULAInEveryNewTab) {
                    // if we want the EULA/Disclaimer to appear in _every_ new tab, use session storage
                    Beacon.sessionStorage["EULA-" + mapConfig.AppId] = Beacon.EULA._affirmativeValue;
                } else {
                    // otherwise, use local storage
                    Beacon.localStorage["EULA-" + mapConfig.AppId] = Beacon.EULA._affirmativeValue;
                }
                
                Beacon.EULA.ShowAlertsIfNeeded();
            },
            "Disagree", function () {
                window.location = "/";
            });

    },

    ShowEulaIfNeeded: function () {
        let storageValue = null;
        if (mapConfig.EULAInEveryNewTab) {
            // if we want the EULA/Disclaimer to appear in _every_ new tab, use session storage
            storageValue = Beacon.sessionStorage["EULA-" + mapConfig.AppId];
        } else {
            // otherwise, use local storage
            storageValue = Beacon.localStorage["EULA-" + mapConfig.AppId];
        }

        if (storageValue != Beacon.EULA._affirmativeValue) {
            // show the eula...
            $.get("/services/fulldisclaimer.aspx?appid=" + mapConfig.AppId).then(function (d) {
                Beacon.EULA.Show(Beacon.EULA._headerHtml + Beacon.EULA._headerDividerHtml + d);
            }).fail(function () {
                Beacon.EULA.Show(Beacon.EULA._headerHtml);
            });
        } else {
            Beacon.EULA.ShowAlertsIfNeeded();
        }
    },

    _headerHtml: "Please read <a href='https://schneidergis.com/softwareterms' target='_blank'>Global Terms of Service <span class='sr-only'>opens in a new tab</span></a>",
    _headerDividerHtml: "<br><hr>",

    ShowAlertsIfNeeded: function () {
        if (mapConfig.BeaconSessionAlerts.length > 0) {
            Beacon.EULA.ShowAlert(mapConfig.BeaconSessionAlerts);
        }
    },

    ShowAlert: function (alerts) {
        var alrt = alerts[0];
        var ssKey = "Alert-" + alrt.ID + "-" + mapConfig.AppId;

        if (Beacon.sessionStorage[ssKey] !== "viewed") {
            Beacon.Dialogs.showWithHtml(
                null,
                alrt.Subject, alrt.Body, true,
                "Close", function () {
                    Beacon.sessionStorage.setItem(ssKey, "viewed");
                    if (alerts.length > 1) {
                        Beacon.EULA.ShowAlert(alerts.slice(1));
                    }
                });
        } else {
            // not viewed, see next one:
            if (alerts.length > 1) {
                Beacon.EULA.ShowAlert(alerts.slice(1));
            }
        }
    },


    //ShowBeta: function () {
    //    Beacon.Dialogs.showWithHtml(
    //        "New Beacon Design",
    //        "Welcome to the new version of Beacon. <br><a href='http://schneidercorp.com/Whats-New/'>What's new in Beacon</a>",
    //        false,
    //        "OK", function () {
    //            // accepted = true;
    //            localStorage["BETA-NEW"] = "true";

    //            // show the eula...
    //            Beacon.EULA.ShowEulaIfNeeded();
    //        },
    //        "Leave", function () {
    //            Beacon.VersionSwitcher.RedirectToOld();
    //        });
    //},

    Initialize: function () {

        //if (localStorage["BETA-NEW"] != "true") {
        //    Beacon.EULA.ShowBeta();
        //    return;
        //}

        Beacon.EULA.ShowEulaIfNeeded();
    },

    CLASS_NAME: 'Beacon.EULA'
};


Beacon.AdvancedDropdown = {

    keyCode: {
        RETURN: 13,
        SPACE: 32,
        PAGEUP: 33,
        PAGEDOWN: 34,
        END: 35,
        HOME: 36,
        LEFT: 37,
        UP: 38,
        RIGHT: 39,
        DOWN: 40,
        SLASH: 191,
        ESCAPE: 27,
        TAB: 9
    },

    configureAdvancedDropdown: function (elementID, defaultValueID, onSelect) {
        var self = this;
        var mouseStateHolder = {
            mouseX: null,
            mouseY: null
        }

        var button = $('#' + elementID + ' .advanced-dropdown-button');
        var menu = $('#' + elementID + ' .advanced-dropdown-content');

        var openMenu = function () {
            //don't open the menu if it's already open
            if (!$(menu).hasClass('show')) {
                //clear search results
                $('#' + elementID).find('.no-match').removeClass('no-match');

                $(button).attr('aria-expanded', true);
                $(menu).addClass('show');
            }
        };
        var closeMenu = function () {
            var wasMenuOpen = $(menu).hasClass('show');

            if ($(button).attr('aria-activedescendant')) {
                //get the currently selected option by the ID of the aria active descendant
                var selectedOption = $('#' + $(button).attr('aria-activedescendant'));

                onSelect($(selectedOption));
                $(button).val($(selectedOption).text());
                //set this so that the options won't all be hidden next time the menu is opened
                $(button).data('previousSearchValue', $(button).val());
            }

            $(button).attr('aria-expanded', false);
            $(menu).removeClass('show');

            return wasMenuOpen;
        };
        var toggleMenu = function () {
            if ($(menu).hasClass('show')) {
                closeMenu();
            } else {
                openMenu();
            }
        };

        var getNextOption = function (option) {
            var availableOptions = $('#' + elementID).find(' .dropdown-option:not(.no-match,.unselected-state)');
            var selectedIdx = availableOptions.index($(option));
            if (selectedIdx < (availableOptions.length - 1)) {
                return $(availableOptions[selectedIdx + 1]);
            }
            return undefined;
        };
        var getPreviousOption = function (option) {
            var availableOptions = $('#' + elementID).find(' .dropdown-option:not(.no-match,.unselected-state)');
            var selectedIdx = availableOptions.index($(option));
            if (selectedIdx > 0) {
                return $(availableOptions[selectedIdx - 1]);
            }
            return undefined;
        }
        var getFirstOption = function () {
            return $(menu).find('.dropdown-option:not(.no-match,.unselected-state)').first();
        };
        var getLastOption = function () {
            return $(menu).find('.dropdown-option:not(.no-match,.unselected-state)').last();
        };

        var changeSelection = function (previousSelection, nextSelection) {
            if ($(previousSelection).length > 0) {
                $(previousSelection).removeClass('dropdown-selected');
                $(previousSelection).removeAttr('aria-selected');
            }

            $(button).attr('aria-activedescendant', $(nextSelection).attr('id'));

            $(nextSelection).addClass('dropdown-selected');
            $(nextSelection).attr('aria-selected', true);
        };

        $('#' + elementID + ' .advanced-dropdown-button').focusin(function (event) {
            openMenu();
        });

        $('#' + elementID + ' .advanced-dropdown-button').mousedown(function (event) {
            toggleMenu();
        });

        $('#' + elementID + ' .advanced-dropdown-button').keydown(function (event) {
            switch (event.keyCode) {
                case self.keyCode.UP:
                    //select the previous option in order, or the last option if no
                    //option is currently selected
                    var currentSelection;
                    var nextSelection;

                    if ($(this).attr('aria-activedescendant')) { //is anything currently selected?
                        currentSelection = $('#' + $(this).attr('aria-activedescendant'));
                        nextSelection = getPreviousOption(currentSelection);
                    } else {
                        nextSelection = getLastOption();
                    }

                    changeSelection(currentSelection, nextSelection);

                    $(menu).prop('scrollTop', $(nextSelection).prop('offsetTop') - 250);

                    event.preventDefault();
                    event.stopPropagation();
                    break;
                case self.keyCode.DOWN:
                    //select the next option in order, or the first option if no option
                    //is currently selected
                    var currentSelection;
                    var nextSelection;

                    if ($(this).attr('aria-activedescendant')) { //is anything currently selected?
                        currentSelection = $('#' + $(this).attr('aria-activedescendant'));
                        nextSelection = getNextOption(currentSelection);
                    } else {
                        nextSelection = getFirstOption();
                    }

                    changeSelection(currentSelection, nextSelection);

                    $(menu).prop('scrollTop', $(nextSelection).prop('offsetTop') - 250);

                    event.preventDefault();
                    event.stopPropagation();
                    break;
                case self.keyCode.HOME:
                case self.keyCode.PAGEUP:
                    //select the first option in the list
                    var currentSelection;
                    var nextSelection;

                    if ($(this).attr('aria-activedescendant')) {
                        currentSelection = $('#' + $(this).attr('aria-activedescendant'));
                    }
                    nextSelection = getFirstOption();

                    changeSelection(currentSelection, nextSelection);

                    $(menu).prop('scrollTop', $(nextSelection).prop('offsetTop') - 250);

                    event.preventDefault();
                    event.stopPropagation();
                    break;
                case self.keyCode.END:
                case self.keyCode.PAGEDOWN:
                    //select the last option in the list
                    var currentSelection;
                    var nextSelection;

                    if ($(this).attr('aria-activedescendant')) {
                        currentSelection = $('#' + $(this).attr('aria-activedescendant'));
                    }
                    nextSelection = getLastOption();

                    changeSelection(currentSelection, nextSelection);

                    $(menu).prop('scrollTop', $(nextSelection).prop('offsetTop') - 250);

                    event.preventDefault();
                    event.stopPropagation();
                    break;
                case self.keyCode.RETURN:
                    toggleMenu();

                    event.preventDefault();
                    event.stopPropagation();
                    break;
                case self.keyCode.ESCAPE:
                case self.keyCode.TAB:
                    if (closeMenu()) {
                        //if the menu was open, prevent the event from bubbling.
                        //if the menu wasn't open, don't prevent the event from bubbling.
                        //this means that if the key pressed was a tab, then the browser
                        //won't immediately move focus to the next element before onSelect()
                        //has a chance to do whatever it does
                        event.preventDefault();
                        event.stopPropagation();
                    }
                    break;
            }
        });

        $('#' + elementID + ' .advanced-dropdown-content').mouseover(function (event) {
            //select the option that the user is hovering over, but don't close the menu
            if ($(event.target).hasClass('dropdown-option') && (mouseStateHolder.mouseX !== event.clientX || mouseStateHolder.mouseY !== event.clientY)) {
                var currentSelection;
                var nextSelection = $(event.target);

                if ($(button).attr('aria-activedescendant')) {
                    currentSelection = $('#' + $(button).attr('aria-activedescendant'));
                }

                changeSelection(currentSelection, nextSelection);

                event.preventDefault();
                event.stopPropagation();
            }

            //Track the position of the mouse. This is done in case the mouse happens to be in
            //the menu while the user is scrolling or navigating the menu with the arrow keys.
            //An element should only be selected if the user actually moves the mouse
            mouseStateHolder.mouseX = event.clientX;
            mouseStateHolder.mouseY = event.clientY;
        });

        $('#' + elementID + ' .advanced-dropdown-content').mousedown(function (event) {
            //select the option that the user clicked, and also close the menu
            if ($(event.target).hasClass('dropdown-option')) {
                var currentSelection;
                var nextSelection = $(event.target);

                if ($(button).attr('aria-activedescendant')) {
                    currentSelection = $('#' + $(button).attr('aria-activedescendant'));
                }

                changeSelection(currentSelection, nextSelection);

                closeMenu();

                event.preventDefault();
                event.stopPropagation();
            }
        });

        $('#' + elementID + ' .advanced-dropdown-button').focusout(function (event) {
            if ($(menu).hasClass('show')) {
                closeMenu();
            }
        });

        //configure the search box to search
        $('#' + elementID + ' .advanced-dropdown-button').keyup(function (event) {
            switch (event.keyCode) {
                case self.keyCode.RETURN:
                case self.keyCode.ESCAPE:
                case self.keyCode.TAB:
                    return;
            }

            //if the user is editing the box, open the menu
            openMenu();

            //only do filtering of the user has actually changed the text in the box
            if ($(this).data('previousSearchValue') !== $(this).val()) {
                var searchText = $(this).val().toUpperCase().trim();
                $('#' + elementID + ' .dropdown-option:not(.all-option)').each(function () {
                    if (searchText === '' || $(this).text().toUpperCase().indexOf(searchText) > -1) {
                        $(this).removeClass('no-match');
                    } else {
                        $(this).addClass('no-match');
                    }
                });
                $('#' + elementID + ' .state-group').each(function () {
                    if ($(this).find('.dropdown-option:not(.no-match)').length < 1) {
                        $(this).addClass('no-match');
                    } else {
                        $(this).removeClass('no-match');
                    }
                });
                //next time the user types, we can compare against this value to determine
                //if the user has actually typed anything
                $(this).data('previousSearchValue', $(this).val());
            }
        });

        $('#' + elementID + ' > .glyphicon-chevron-down').mousedown(function (event) {
            if (!$(menu).hasClass('show')) {
                $('#' + elementID + ' .advanced-dropdown-button').focus();
                event.preventDefault();
                event.stopPropagation();
            }
        });

        if (defaultValueID && $('#' + defaultValueID).length > 0) {
            changeSelection($(), $('#' + defaultValueID));
            closeMenu();
        }
    },

    configureMultiSelect: function (elementID, onSelect, onDelete) {
        if (!onDelete) {
            onDelete = (deletedItem) => null; // just a function that does nothing
        }

        let destination = $('#' + elementID).find('.advanced-multiselect-destination');
        let onMultiSelect = function (selectedElement) {
            let newSelectionSpan = $('<span></span>');

            let newSelection = $('<span class="advanced-multiselect-selection">' + $(selectedElement).text() + '</span>');
            $(newSelectionSpan).append(newSelection);

            let deleteButton = $('<span>X</span>');
            $(deleteButton).on('click', function () {
                $(newSelectionSpan).remove();
                onDelete(newSelection);
            });
            $(newSelectionSpan).append($(deleteButton));

            $(destination).append($(newSelectionSpan));

            onSelect(selectedElement);
        };
        this.configureAdvancedDropdown(elementID, null, onMultiSelect);
    },

    CLASS_NAME: 'Beacon.AdvancedDropdown'
};
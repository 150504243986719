
Beacon.Variables = {
    // should match with Variables.less items
    smallLayoutWidth: 470,
    mediumLayoutWidth: 700,

    isDesktopViewport: function () {
        return Beacon.Variables.viewportWidth() >= Beacon.Variables.mediumLayoutWidth;
    },

    viewportWidth: function () {

        // from https://github.com/ryanve/verge/blob/master/verge.js
        // -- seems to be more reliable than other methods

        var win = typeof window != 'undefined' && window;
        var doc = typeof document != 'undefined' && document;
        var docElem = doc && doc.documentElement;

        var a = docElem['clientWidth'], b = win['innerWidth'];
        return a < b ? b : a;

    }

};

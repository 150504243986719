
Beacon.API = {

    pBeaconCore: null,

    Initialize: function () {
        this.pBeaconCore = new wcfServiceProxy('/api/beaconCore/', mapConfig.QPS);
    },

    UpdateMapExtent: function (ext, resolution, success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("UpdateMapExtent", 15000,
            {
                ext: { minx: ext.left, miny: ext.bottom, maxx: ext.right, maxy: ext.top },
                resolution: resolution,
                ts: (new Date()).getTime()
            },
            success, failure, false, scope, scopeArgs);
    },

    SetResults: function (layerId, keys, success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("SetResults", 15000,
            {
                keys: keys,
                layerId: layerId,
                ts: (new Date()).getTime()
            },
            success, failure, false, scope, scopeArgs);
    },

    SetOrderedResults: function (layerId, keys, success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("SetOrderedResults", 15000,
            {
                keys: keys,
                layerId: layerId,
                ts: (new Date()).getTime()
            },
            success, failure, false, scope, scopeArgs);
    },

    //SetSearchIntent: function (layerId, searchIntent, success, failure, scope, scopeArgs) {
    //    this.pBeaconCore.invoke("SetSearchIntent", 15000,
    //        {
    //            layerId: layerId,
    //            searchIntent: searchIntent,
    //            ts: (new Date()).getTime()
    //        },
    //        success, failure, false, scope, scopeArgs);
    //},


    GetVectorLayer: function (layerId, useSelection, ext, wkt, spatialRelation, featureLimit, success, failure, scope, scopeArgs, projectionOverride, auxKeyField) {
        this.pBeaconCore.invoke("GetVectorLayer", 30000,
            {
                layerId: layerId,
                useSelection: useSelection,
                ext: ext ? { minx: ext.left, miny: ext.bottom, maxx: ext.right, maxy: ext.top } : null,
                wkt: wkt,
                spatialRelation: spatialRelation,
                featureLimit: featureLimit,
                projectionOverride: projectionOverride,
                auxKeyField: auxKeyField
            },
            success,
            failure,
            false,
            scope,
            scopeArgs);
    },

    InvokeGeoProcService: function (layerId, method, targetLayer, selectionKey, jobId, wkid, success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("InvokeGeoProcService", 30000,
            {
                layerId: layerId,
                method: method,
                targetLayer: targetLayer,
                selectionKey: selectionKey,
                wkid: wkid,
                jobId: jobId
            },
            success,
            failure,
            false,
            scope,
            scopeArgs);
    },

    GetAttributes: function (layerId, attributes, distinct, featureLimit, success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("GetAttributes", 30000,
            {
                layerId: layerId,
                attributes: attributes,
                distinct: distinct,
                featureLimit: featureLimit
            },
            success,
            failure,
            false,
            scope,
            scopeArgs);
    },

    QueryWithWhere: function (layerId, where, featureLimit, success, failure, scope, scopeArgs, projectionOverride, auxKeyField) {
        this.pBeaconCore.invoke("QueryWithWhere", 30000,
            {
                layerId: layerId,
                where: where,
                featureLimit: featureLimit,
                projectionOverride: projectionOverride,
                auxKeyField: auxKeyField
            },
            success,
            failure,
            false,
            scope,
            scopeArgs);
    },
    
    SpatialRelation: {
        None: 0,
        Intersects: 1,
        Contains: 2,
        ContainsCentroid: 3
    },

    QueryMapDetail: function (layerId, key, success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("QueryMapDetail", 15000,
            {
                layerId: layerId,
                key: key
            },
            success,
            failure,
            false,
            scope,
            scopeArgs);
    },

    BufferGeometry: function (wkts, distance, success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("BufferGeometry", 31000,
            {
                distance: distance,
                wkt: wkts
            },
            success,
            failure,
            false,
            scope,
            scopeArgs);
    },

    ClearBufferGeometry: function (success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("ClearBufferGeometry", 15000,
            {},
            success,
            failure,
            false,
            scope,
            scopeArgs);
    },

    BufferAndExport: function (layerId, keys, distance, exportType, showParcelId, usePropertyAddress, skipLabelCount, success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("BufferAndExport", 60000,
            {
                layerId: layerId,
                keys: keys,
                distance: distance,
                exportType: exportType,
                showParcelId: showParcelId,
                usePropertyAddress: usePropertyAddress,
                skipLabelCount: skipLabelCount
            },
            success,
            failure,
            false,
            scope,
            scopeArgs
        )
    },

    GetTabs: function (layerId, success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("GetTabs", 15000,
            {
                layerId: layerId
            },
            success,
            failure,
            false,
            scope,
            scopeArgs);
    },
    AnnouncementAcknowledgement: function (layerId, announcementId, success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("AnnouncementAcknowledgement", 15000,
            {
                appId: layerId,
                announcementId: announcementId

            },
            success,
            failure,
            false,
            scope,
            scopeArgs);
    },
    GetModuleJurisdictions: function (ModuleName, success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("GetModuleJurisdictions", 15000,
            {
                ModuleName: ModuleName
            },
            success,
            failure,
            false,
            scope,
            scopeArgs);
    },
    GetVendorJurisdictions: function (VendorName, success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("GetVendorJurisdictions", 15000,
            {
                VendorName: VendorName
            },
            success,
            failure,
            false,
            scope,
            scopeArgs);
    },
    GetDisclaimers: function (layerIds, success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("GetDisclaimers", 15000,
            {
                layerIds: layerIds
            },
            success,
            failure,
            false,
            scope,
            scopeArgs);
    },

    GetQuickZoomList: function (layerId, success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("GetQuickZoomList", 15000,
                {
                    layerId: layerId
                },
                success,
                failure,
                false,
                scope,
                scopeArgs);
    },

    LogJsError: function (message, stacktrace, success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("LogJsError", 60000,
            {
                msg: message,
                stacktrace: stacktrace
            },
            success,
            failure,
            false,
            scope,
            scopeArgs);
    },

    Ping: function (success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("Ping", 15000,
            {},
            success,
            failure,
            false,
            scope,
            scopeArgs);
    },

    SetLayerVisibility: function (layerId, visible, success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("SetLayerVisibility", 15000,
            { layerId: layerId, visible: visible },
            success,
            failure,
            false,
            scope,
            scopeArgs);
    },

    SetLayersVisibility: function (layersVisibility, success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("SetLayersVisibility", 15000,
            { layersVisibility: layersVisibility },
            success,
            failure,
            false,
            scope,
            scopeArgs);
    },

    SetLayersNotVisible: function (layerIds, success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("SetLayersNotVisible", 15000,
            { layerIds: layerIds },
            success,
            failure,
            false,
            scope,
            scopeArgs);
    },

    SetLayersVisible: function (layerIds, success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("SetLayersVisible", 15000,
            { layerIds: layerIds },
            success,
            failure,
            false,
            scope,
            scopeArgs);
    },

    SaveMapMarkup: function (markup, success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("SaveMapMarkup", 15000,
            {
                markup: markup,
                ts: (new Date()).getTime()
            },
            success,
            failure,
            false,
            scope,
            scopeArgs);
    },

    LoadMapMarkup: function (success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("LoadMapMarkup", 15000,
            {},
            success,
            failure,
            false,
            scope,
            scopeArgs);
    },

    SaveMapMeasure: function (markup, success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("SaveMapMeasure", 15000,
            {
                markup: markup,
                ts: (new Date()).getTime()
            },
            success,
            failure,
            false,
            scope,
            scopeArgs);
    },

    PostDataEdits: function (edits, success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("PostDataEdits", 60000,
            {
                data: edits
            },
            success,
            failure,
            false,
            scope,
            scopeArgs);
    },

    GetCurrentBalance: function (success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("GetCurrentBalance", 15000,
                {},
                success,
                failure,
                false,
                scope,
                scopeArgs);
    },

    GetMapState: function (name, success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("GetMapState", 15000,
            {
                name: name
            },
            success, failure, false, scope, scopeArgs);
    },

    SaveMapState: function (name, state, success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("SaveMapState", 15000,
            {
                name: name,
                state: state
            },
            success, failure, false, scope, scopeArgs);
    },

    DeleteMapState: function (name, success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("DeleteMapState", 15000,
            {
                name: name
            },
            success, failure, false, scope, scopeArgs);
    },

    GetLegendInfos: function (appId, success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("GetLegendInfos", 15000,
            {
                appId: appId
            },
            success, failure, false, scope, scopeArgs);
    },

    GetServiceLayerInfo: function (spatialServerId, layerId, success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("GetServiceLayerInfo", 15000,
            {
                spatialServerId: spatialServerId,
                layerId: layerId
            },
            success, failure, false, scope, scopeArgs);
    },

    GetServiceLegend: function (spatialServerId, success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("GetServiceLegend", 15000,
            {
                spatialServerId: spatialServerId
            },
            success, failure, false, scope, scopeArgs);
    },


    GetMapImpage: function (bbox, width, height, dpi, clientGraphics, success, failure, scope, scopeArgs) {
        this.pBeaconCore.invoke("GetMapImpage", 60000,
            {
                bbox: bbox,
                width: width,
                height: height,
                dpi: dpi,
                clientGraphics: clientGraphics
            },
            success, failure, false, scope, scopeArgs);
    }
};

Beacon.Tabs = {

    _templateHtmlPre: null,
    _templateHtmlPost: null,
    _templateTarget: null,

    _wizards: [], // formerly: the stearns user signup pages - special treatment where we only show one tab

    _userLoggedIn: false,

    hasPageType: function (pagetype) {

        for (var i = 0, len = mapConfig.Tabs.length; i < len; i++) {
            var tab = mapConfig.Tabs[i];

            if (tab.PageTypeId == pagetype) { //Reports and Forms
                return true;
            }

        }

        return false;

    },

    getReportLinks: function (key, pagetype) {


        if (!pagetype) pagetype = 4;

        var reports = [];
        for (var i = 0, len = mapConfig.Tabs.length; i < len; i++) {
            var tab = mapConfig.Tabs[i];

            if (tab.PageTypeId == pagetype) { //Reports and Forms
                reports.push({
                    name: tab.Name.replace(/.+\\/g, ''),
                    //url: tab.Url + '&KeyValue=' + escape(key)
                    url: this.getUrl(mapConfig.LayerId, tab.PageTypeId, tab.PageId, key)
                });
            }
        }
        return reports;




    },

    getReportLinksHtml: function (key, pagetype, listPrefix, listSeparator, extraParams) {


        if (!listPrefix) listPrefix = "View: ";
        if (!listSeparator) listSeparator = "&nbsp;|&nbsp;";
        if (!pagetype) pagetype = 4;
        if (!extraParams) extraParams = '';

        var reports = Beacon.Tabs.getReportLinks(key, pagetype);
        if (reports.length > 0) {
            var reportLinks = $.map(reports, function (d) {
                if (d.name) {
                    return '<a href="' + d.url + extraParams + '">' + d.name + '</a>';
                } else {
                    return null;
                }
            });
            // fabricate a gMaps link
            if (mapConfig.Permissions.Streetview) {
                reportLinks.push('<a href="#" title="" target="_blank" onclick="Beacon.Control.GoogleMaps.showFeature(\'' + key + '\'); return false;">Google Maps <span class="sr-only">opens in a new tab</span></a>');
            }
            return "<div>" + (listPrefix + reportLinks.join(listSeparator)) + "</div>";
        } else {
            return "";
        }


    },

    generateTabStrip: function (bItemSelected, keyValue) {
        if (!Handlebars.helpers.equals) {
            Handlebars.registerHelper('equals', function (operand1, operand2) {
                return operand2 === operand1;
            });
        }

        if (!Handlebars.helpers.isGroup) {
            Handlebars.registerHelper('isGroup', function (pages) {
                return pages.length > 1;
            });
        }

        //reinitialize the model for the tab strip
        this.tabStripModel = {
            tabs: {},
            activePage: null,
            loginUrl: Beacon.Tabs._userLoggedIn ? "/Account" : "/Account/Login?ReturnUrl=" + encodeURIComponent(window.location.href),
            loginText: Beacon.Tabs._userLoggedIn ? "My Account" : "Login"
        }


        var isThisLayerAWizard = _.contains(this._wizards, mapConfig.LayerId);
        if (keyValue === '') { keyValue = null; }
        var hasKey = (keyValue != null);
        var activePageId = mapConfig.PageId;
        var activePageTypeId = mapConfig.PageTypeId;
        var pages = mapConfig.Tabs;

        // for (page of pages) { I'd be nice to use modern JavaScript, but IE is still a thing I guess...
        for (var i = 0; i < pages.length; i++) {
            var page = pages[i];

            //get some information about the tab
            var isMapTab = (page.PageTypeId == activePageTypeId && page.PageTypeId == 1); //handles cases where pageid is zero for magically placed map tabs
            var isActivePage = (page.PageId == activePageId && page.PageTypeId == activePageTypeId) || isMapTab;
            var isTabEnabled = ((!page.nr || bItemSelected) && (!page.nk || hasKey));

            //only pages that will actually be present in the tab strip will be in this model
            if (!isTabEnabled) {
                continue;
            } else if (!page.Name) {
                continue;
            } else if (isActivePage) {
                this.tabStripModel.activeTab = page
            } else if (isThisLayerAWizard) {
                continue;
            }

            //get the tab that this page will live in
            var tabName = page.Name.indexOf('\\') > -1 ? page.Name.match(/.*(?=\\)/g)[0] : page.Name;
            if (!this.tabStripModel.tabs[tabName]) {
                this.tabStripModel.tabs[tabName] = {
                    groupName: tabName,
                    groupID: tabName.replace(/^[0-9]*|[^a-zA-Z0-9]/g, '').toLowerCase() + i,
                    pages: [],
                    hasActivePage: false
                };
                this.tabStripModel.numTabs++;
                
            }
            var tab = this.tabStripModel.tabs[tabName];

            //determine whether or not this tab contains the active page
            tab.hasActivePage = isActivePage || tab.hasActivePage;

            tab.pages.push({
                name: !(page.Name.indexOf('\\') > -1) ? page.Name : page.Name.match(/\\.*/g)[0].substring(1),
                pageType: page.PageTypeId,
                url: (page.NavigateUrl || this.getUrl(mapConfig.LayerId, page.PageTypeId, page.PageId, keyValue)),
                locked: !page.p,
                isActive: isActivePage,
                moreTabElementID: page.Name.replace(/^[0-9]*|[^a-zA-Z0-9]/g, '').toLowerCase() + 'moretab' + i
            });
        }

        var tabContent = $("#tabRow .tab-template-area");
        tabContent.html(Beacon.Templates.tabs(this.tabStripModel));


        $("a[role=tab],a[role=button]", $("#tabRow")).keydown(function (e) {

            var tabEls = $("a[role=tab]:visible,a[role=button]:visible", $("#tabRow"));
            var selectedIndex = tabEls.index(e.target);

            switch (e.keyCode) {
                case Beacon.WCAG.keyCode.SPACE: //space
                    e.target.click();
                    e.preventDefault();
                    e.stopPropagation();
                    break;
                case Beacon.WCAG.keyCode.LEFT: //left arrow
                    if(selectedIndex > 0) selectedIndex -= 1;
                    tabEls[selectedIndex].focus();
                    e.preventDefault();
                    e.stopPropagation();
                    break;
                case Beacon.WCAG.keyCode.RIGHT: //right arrow
                    if (selectedIndex < (tabEls.length - 1)) selectedIndex += 1;
                    tabEls[selectedIndex].focus();
                    e.preventDefault();
                    e.stopPropagation();
                    break;
            }
        });

        if (mapConfig.PageTypeId == 1) {
            this.loadQuickZoomMenu(mapConfig.LayerId);
        }

        this.onResize();
    },

    onResize: function () {
        if (this.tabStripModel && this.tabStripModel.tabs) {
            var maxWidth;
            if ($('#tabAffix .container').length > 0) {
                //the container is the standard for tab strip width
                maxWidth = $('#tabAffix .container').width();
            } else {
                //otherwise go wor the width of the window
                maxWidth = $('#tabAffix').width();
            }
            //be sure to subtract out padding, if any
            maxWidth -= ($('#tabRow').outerWidth() - $('#tabRow').width()) + 1;
            var stripwidth = $('#tabMenuMore').width();
            //add up the widths of all the other stuff that is on the same bar
            $('#tabAffix .beacon-navbar-nav:not(.tab-template-area)').each(function () {
                stripwidth += $(this).width();
            });
            var stripFull = false;
            for (var tab in this.tabStripModel.tabs) {
                tab = this.tabStripModel.tabs[tab];

                stripFull = stripFull || (stripwidth + $('#' + tab.groupID).width()) > maxWidth;

                if (stripFull) {
                    //if there's no more room in the tab strip, hide the remaining tabs and ensure
                    //that all the pages contained in the tab are accessible in the 'more' tab
                    $('#' + tab.groupID).addClass('tab-overflow');
                    //for (let page of tab.pages) { //Alas, no modern JavaScript for us...
                    for (var j = 0; j < tab.pages.length; j++) {
                        var page = tab.pages[j];
                        $('#' + page.moreTabElementID).removeClass('tab-underflow');
                    }
                } else {
                    //if there's still room in the tab strip, show this tab, and ensure that
                    //the corresponding links are not visible in the 'more' tab
                    $('#' + tab.groupID).removeClass('tab-overflow');
                    stripwidth += $('#' + tab.groupID).width();
                    for (var j = 0; j < tab.pages.length; j++) {
                        var page = tab.pages[j];
                        $('#' + page.moreTabElementID).addClass('tab-underflow');
                    }
                }
            }
        }

        var someItemsHidden = $(".tab-item-bar:hidden:not(#tabQuickZoomDropdown)").length > 0;
        var desktopSize = $("#tabAffix").width() >= Beacon.Variables.mediumLayoutWidth;
        //var smallSize = $("#tabRow").width() >= Beacon.Variables.smallLayoutWidth;

        if (someItemsHidden || !desktopSize) {
            $("#tabMenuMore").show();
        } else {
            $("#tabMenuMore").hide();
        }

        // set max height for zoom dropdown
        if (window.mapConfig && (mapConfig.PageTypeId == 1)) {
            var mapViewHeight = $("#olMap").height();
            $("#tabQuickzoomList").css({
                "max-height": mapViewHeight - 32,
                "overflow-y": "auto"
            });
        }
    }, 

    initialize_generic: function() {
        var self = this;
        $(window).bind("resize", function () {
            self.onResize();
        });
        self.onResize();

        $("a[role=tab],a[role=button]", $("#tabRow")).keydown(function (e) {

            var tabEls = $("a[role=tab]:visible,a[role=button]:visible", $("#tabRow"));
            var selectedIndex = tabEls.index(e.target);

            switch (e.keyCode) {
                case Beacon.WCAG.keyCode.SPACE: //space
                    e.target.click();
                    e.preventDefault();
                    break;
                case Beacon.WCAG.keyCode.LEFT: //left arrow
                    if (selectedIndex > 0) selectedIndex -= 1;
                    tabEls[selectedIndex].focus();
                    break;
                case Beacon.WCAG.keyCode.RIGHT: //right arrow
                    if (selectedIndex < (tabEls.length - 1)) selectedIndex += 1;
                    tabEls[selectedIndex].focus();
                    break;
            }
        });

    },

    initialize: function (userLoggedIn) {

        //Beacon.VersionSwitcher.AttachToButton();

        Beacon.Tabs._userLoggedIn = userLoggedIn;

        Beacon.Tabs.generateTabStrip(mapConfig.ResultCount, mapConfig.KeyValue);

        var self = this;
        $(window).bind("resize", function () {
            self.onResize();
        });
        self.onResize();


        // map vs non-map layer menu display:
        if (mapConfig.PageTypeId != 1) {
            this.loadSimpleLayerList();

        } else {
            // TOC/Layer list support:
            $("#tabRow .ddl-layer-list").click(this.toggleLayersMenu);

            if (!Beacon.Variables.isDesktopViewport()) {
                // queue this to happen a bit later - .map-wrap doesn't exist yet
                setTimeout(function () {
                    $('.map-wrap').addClass('hide-map-pane-layers');
                }, 0);
                $('#tabRow .ddl-layer-list a[aria-expanded]').attr('aria-expanded', 'false');
            }
            else {
                $('#tabRow .ddl-layer-list a[aria-expanded]').attr('aria-expanded', 'true');
            }

            $("#tabRow .ddl-layer-list").show();
        }

    },

    toggleLayersMenu: function (e) {
        // contect: click event of the dropdown menu as well as from the (x) button in the toc

        if ($('.map-wrap').hasClass('hide-map-pane-layers')) {
            $('.map-wrap').removeClass('hide-map-pane-layers');
            $('#tabRow .ddl-layer-list a[aria-expanded]').attr('aria-expanded', 'true');
        } else {
            $('.map-wrap').addClass('hide-map-pane-layers');
            $('#tabRow .ddl-layer-list a[aria-expanded]').attr('aria-expanded', 'false');
        }

        if (Beacon.MapJS.map?.updateSize) {
            Beacon.MapJS.map.updateSize();
        } if (Beacon.MapJS.forceTileCacheRedraw) {
            Beacon.MapJS.forceTileCacheRedraw();
        }
    },

    hideLayersMenu: function(e) {
        $('.map-wrap').addClass('hide-map-pane-layers');
        if (Beacon.MapJS.map?.updateSize) {
            Beacon.MapJS.map.updateSize();
        } if (Beacon.MapJS.forceTileCacheRedraw) {
            Beacon.MapJS.forceTileCacheRedraw();
        }
    },

    getUrl: function (layerId, pageTypeId, pageId, key, specialPage) {

        //derive spcial page url or generate using current url's page
        var page;
        if (specialPage) {
            page = '/' + specialPage;
        } else {
            page = window.location.pathname;
        }

        var a = [
            page,
            '?AppID=',
            mapConfig.AppId,
            '&LayerID=',
            layerId
        ];

        if (pageTypeId) {
            a.push("&PageTypeID=");
            a.push(pageTypeId);
        }

        if (pageId) {
            a.push("&PageID=");
            a.push(pageId);
        }


        if (key && (key != '')) {
            a.push('&KeyValue=');
            //a.push(escape(key));
            a.push(encodeURIComponent(key)); // Should be safe to use encodeURIComponent as we are only encoding the key (and we need a thorough encoding here)...AL
        }

        return a.join('');

    },

    loadQuickZoomMenu: function (layerId) {

        //$("#tabQuickzoomList").html("<li><a href='#'>Loading data...</a></li>");
        $("#tabQuickZoomDropdown").hide();

        Beacon.QuickZoomService.requestQuickZoomData(layerId).then(function (id, data) {

            if (data && data.length > 0) {

                var t = Beacon.Templates.quickzoom;
                $("#tabQuickzoomList").html(t({ QZ: data }));
                $("#tabQuickZoomDropdown").show();

                // wire up events
                $("#tabQuickzoomList").click(function (e) {
                    var kv = e.target.getAttribute("keyvalue");
                    Beacon.MapJS.selectionLayer.zoomAndSelectByKeyValue(kv);
                });

            }
        });

    },


    loadSimpleLayerList: function () { // layer list for non-map pages 

        var self = this;

        // build view model:

        var lyrs = [];
        _.forIn(mapConfig.LayerView, function (lyr) {
            // top level layers & groups
            if (lyr.GroupName) {
                // group layer:
                var qSubLyrs = $.map(lyr.Layers, function (slyr) {
                    if (slyr.Query) {
                        return {
                            LayerName: slyr.LayerName,
                            ActiveLayer: slyr.LayerId === mapConfig.LayerId,
                            LayerChangeUrl: self.getUrl(slyr.LayerId)
                        };
                    } else {
                        return null;
                    }
                });
                if (qSubLyrs.length > 0) { //dont add a group if there are no sub layers visible
                   // lyr.Layers = qSubLyrs;
                    lyrs.push({ // create a new group object, because we mess with the layers list:
                        GroupName: lyr.GroupName,
                        Layers: qSubLyrs
                    });
                }
            } else {
                // top level layer
                if (lyr.Query) {
                    lyrs.push({
                        LayerName: lyr.LayerName,
                        ActiveLayer: lyr.LayerId === mapConfig.LayerId,
                        LayerChangeUrl: self.getUrl(lyr.LayerId)
                    });
                }
            }
        });


        // render the toc
        $("#ddlSimpleLayerList").html(Beacon.Templates.simpleLayerList({
            Layers: lyrs,
            Links: mapConfig.Links
        }));

        $(".simple-layer-dropdown-li").on("show.bs.dropdown", function () {
            $("#ddlSimpleLayerList").css("max-height", $(window).height() - 140);
        });

        $(".simple-layer-dropdown-li").show();
    },



    CLASS_NAME: "Beacon.Tabs"

};

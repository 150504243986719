// Browser Hackery - turn your eyes away!

// vml support for IE6-8 - this somehow makes OL work - otherwise document.namespaces always throws an exception
if ((window.navigator.appName.toLowerCase().indexOf("microsoft") >= 0)) {
    document.writeln('<xml:namespace ns="urn:schemas-microsoft-com:vml" prefix="v"/>\n');
    document.writeln('<style type="text/css"> v\\:* { behavior: url(#default#VML);} </style>\n');
}

// --- end of hackery


// Normal Code:
Beacon.BrowserCompatibility = {

    unsupportedBrowser: false,
    legacyBrowser: false,
    limitResults: 0,

    Detect: function () {

        var v;
        var ua = navigator.userAgent;

        // detect IE and version
        if (/MSIE (\d+\.\d+);/.test(ua)) {

            //This is Internet Explorer
            v = new Number(RegExp.$1);

            if (v && v < 9) Beacon.BrowserCompatibility.limitResults = 25;

            Beacon.BrowserCompatibility.unsupportedBrowser = (v < 8);
            Beacon.BrowserCompatibility.legacyBrowser = (v >= 8 && v < 9);

            var msg = "";

        }

    },

    Notify: function () {
        var msg = "";
        if (Beacon.BrowserCompatibility.unsupportedBrowser) {
            msg = "Internet Explorer 6 and 7 are not suitable for viewing this site.  <a href='https://browser-update.org/update.html'>Please consider upgrading to a modern browser</a>.";
        }

        if (Beacon.BrowserCompatibility.legacyBrowser) {
            msg = "Internet Explorer 8 is not optimal for viewing this site.  <a href='https://browser-update.org/update.html'>Please consider upgrading to a modern browser</a>.";
        }

        if (msg) {
            var n = $(".headerNotifications");
            var n1 = $(".headerNotification1");

            n1.html(msg);
            n.slideDown();
        }

    }
}

// on page load:
$(function () {
    Beacon.BrowserCompatibility.Detect();
    Beacon.BrowserCompatibility.Notify();
});


function nukeSettings() {

    localStorage.clear();

    sessionStorage.clear();

    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }

}
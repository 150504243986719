Beacon.Dialogs = {
    Node : function (element) {
        // constructor 
        return {
            element: element,
            next: null
        }
    },

    linkedModals : {

        head: null,
        size: 0,
        add: function (element) {
            // creates a new node 
            var node = new Beacon.Dialogs.Node(element);
            // to store current node 
            var current;

            // if list is Empty add the 
            // element and make it head 
            if (this.head == null)
                this.head = node;
            else {
                current = this.head;

                // iterate to the end of the 
                // list 
                while (current.next) {
                    current = current.next;
                }

                // add node 
                current.next = node;
            }
            this.size++;
        },
        removeFrom: function (index) {
            var curr, prev, it = 0;
            curr = this.head;
            prev = curr;
            //  deleting first element 
            if (index === 0) {
                this.head = curr.next;
            }
            this.size--;
            return curr.element;
        }
    },

    // Wraps bootstrap's Modal dialog tool

    showDialog: function (chainedModal) {
        var me = this;
        if (chainedModal.Button1) {
            $("a.button-1", chainedModal.m).click(chainedModal.Action1);
            Beacon.WCAG.addSpacebarSupport($("a.button-1", chainedModal.m));
        }

        if (chainedModal.Button2) {
            $("a.button-2", chainedModal.m).click(chainedModal.Action2);
            Beacon.WCAG.addSpacebarSupport($("a.button-2", chainedModal.m));
        }

        chainedModal.m.on({
            'hidden': function () {
                chainedModal.m.remove();
                linkedModals.removeFrom(0);
                var v = linkedModals.head;
                if (v) {
                    me.showDialog(v.element);
                }
                },
            'hidden.bs.modal': function () {
                // wcag - focus on element that opened the dialog.
                if (chainedModal.ReturnTargetelement) {
                    aria.Utils.attemptFocus(chainedModal.ReturnTargetelement);
                }

                Beacon.Dialogs.linkedModals.removeFrom(0);
                var v =
                    Beacon.Dialogs.linkedModals.head;
                if (v) {
                    me.showDialog(v.element);
                }
            },
            'shown.bs.modal': function () {
                // wcag - need to focus on first focusable item in the dialog
                aria.Utils.focusFirstDescendant($(".modal-focus-target", chainedModal.m)[0]);
            }
        });

        var options = {
            backdrop: 'static',
            keyboard: true
        };

        if (chainedModal.m.OnlyUseButtons) {

            options.backdrop = 'static';
            $(".modal-header button", chainedModal.m).hide();
        }

        // constrain the height of a dialog when shown on a larger window - to avoid the scrolling gunk
        if ($(window).height() > 500) {
            $(".modal-body", chainedModal.m).css({
                maxHeight: $(window).height() - 221,
                overflowY: "auto"
            });
        }

        chainedModal.m.modal(options);


    },


    showWithHtml: function (returnTargetelement, headerTitle, bodyHtml, onlyUseButtons, button1, action1, button2, action2) {

        var z = $(Beacon.Templates.modalDialogHtml({
            Title: headerTitle,
            Body: bodyHtml,
            Button1: button1,
            Button2: button2,
            ShowClose: !onlyUseButtons,
            
        }));
        chainedModal = {
            m: z,
            Action1: action1,
            Action2: action2,
            ReturnTargetelement: returnTargetelement,
            OnlyUseButtons: onlyUseButtons,
            Button1: button1,
            Button2: button2,
            nextModal :null
        };


        Beacon.Dialogs.linkedModals.add(chainedModal);

        if (
            Beacon.Dialogs.linkedModals.size===1) {
            var v =
                Beacon.Dialogs.linkedModals.head.element;
            this.showDialog(v);
        }
    },

    showFromIFrame: function (returnTargetelement, headerTitle, url) {

        var m = $(Beacon.Templates.modalDialogIFrame({
            Title: headerTitle,
            Url:url
        }));

        var modalbody = $(".modal-body", m);

        m.on({
            'shown': function () {
                //recalc height after dialog is hydrated into the dom
                modalbody.innerHeight(m.outerHeight() - $(".modal-header", m).outerHeight());
            },
            'hidden': function () {
                m.remove();
            },
            'hidden.bs.modal': function () {
                // wcag - focus on element that opened the dialog.
                if (returnTargetelement) {
                    aria.Utils.attemptFocus(returnTargetelement);
                }
            },
            'shown.bs.modal': function () {
                // wcag - need to focus on first focusable item in the dialog
                aria.Utils.focusFirstDescendant($(".modal-focus-target", m)[0]);
            }
        });

        var options = {
            backdrop: 'static',
            keyboard: true
        };

        m.modal(options);

    },

    CLASS_NAME: 'Beacon.Dialogs'

};


(function () {

    // this code adapted from samples at http://w3c.github.io/aria-practices/examples/dialog-modal/dialog.html

    window.aria = window.aria || {};
    aria.Utils = aria.Utils || {};

    aria.Utils.isFocusable = function (element) {
        if (element.tabIndex > 0 || (element.tabIndex === 0 && element.getAttribute('tabIndex') !== null)) {
            return true;
        }

        if (element.disabled) {
            return false;
        }

        switch (element.nodeName) {
            case 'A':
                return !!element.href && element.rel != 'ignore';
            case 'INPUT':
                return element.type != 'hidden' && element.type != 'file';
            case 'BUTTON':
            case 'SELECT':
            case 'TEXTAREA':
                return true;
            default:
                return false;
        }
    };

    /*
     * @desc Set focus on descendant nodes until the first focusable element is
     *       found.
     * @param element
     *          DOM node for which to find the first focusable descendant.
     * @returns
     *  true if a focusable element is found and focus is set.
     */
    aria.Utils.focusFirstDescendant = function (element) {
        for (var i = 0; i < element.childNodes.length; i++) {
            var child = element.childNodes[i];
            if (aria.Utils.attemptFocus(child) ||
                aria.Utils.focusFirstDescendant(child)) {
                return true;
            }
        }
        return false;
    }; // end focusFirstDescendant

    /*
     * @desc Find the last descendant node that is focusable.
     * @param element
     *          DOM node for which to find the last focusable descendant.
     * @returns
     *  true if a focusable element is found and focus is set.
     */
    aria.Utils.focusLastDescendant = function (element) {
        for (var i = element.childNodes.length - 1; i >= 0; i--) {
            var child = element.childNodes[i];
            if (aria.Utils.attemptFocus(child) ||
                aria.Utils.focusLastDescendant(child)) {
                return true;
            }
        }
        return false;
    }; // end focusLastDescendant

    /*
     * @desc Set Attempt to set focus on the current node.
     * @param element
     *          The node to attempt to focus on.
     * @returns
     *  true if element is focused.
     */
    aria.Utils.attemptFocus = function (element) {
        if (!aria.Utils.isFocusable(element)) {
            return false;
        }

        try {
            element.focus();
        }
        catch (e) {
        }
        return (document.activeElement === element);
    }; // end attemptFocus

    }());


Beacon.ModuleHeader = {

    minusIcon: 'icon-minus-squared-alt',
    plusIcon: 'icon-plus-squared-alt',

    Initialize: function () {

        this.wireUpClickHandler();
        this.processInitialCookie();

    },

    wireUpClickHandler: function () {

        //was: $(".toggle-collapse, .toggle-collapse ~ .title").click(this.onClick);  
        //todo: handle non-module headers

        $(".toggle-collapse").click(this.onClick);      

    },

    onClick: function() {
        var btn = $(this);
        var header = btn.parent("header.module-header");
        var content = header.next(".module-content, .collapse-content");
        var moduleId = header.attr("MODULEID");

        var knob = $("span.toggle-collapse-icon", header);
        var slipperyKnob=btn.find(">:first-child").hasClass('invisible');
        
         if (moduleId) {

            var hiddenModuleIds = Beacon.ModuleHeader.getHiddenModulesFromCookie();
            var visibleModuleIds = Beacon.ModuleHeader.getVisibleModulesFromCookie();
            var defaultVisibility = header.attr("DEFAULTVISIBILITY");

            var moduleIsPreviouslyHidden = $.inArray(moduleId, hiddenModuleIds) > -1;
            var moduleIsPreviouslyVisible = $.inArray(moduleId, visibleModuleIds) > -1;


            if (!moduleIsPreviouslyHidden && !moduleIsPreviouslyVisible) {
                // We are here if there are no corresponding cookies, so populate list based off of default visiblity...AL
                if (defaultVisibility === "True") {
                    visibleModuleIds.push(moduleId);
                }
                else {
                    hiddenModuleIds.push(moduleId);
                }
            }



            if ($.inArray(moduleId, hiddenModuleIds) === -1) {
                // not found - so its visible, now hide it  //if the knob isn't turned off
                if (!slipperyKnob) {
                    content.slideUp();
                    header.addClass("NoPrint");
                    knob.removeClass(Beacon.ModuleHeader.minusIcon).addClass(Beacon.ModuleHeader.plusIcon);
                    btn.attr('aria-expanded', 'false');
                    hiddenModuleIds.push(moduleId);
                    visibleModuleIds = $.grep(visibleModuleIds, function (item, index) { return item !== moduleId });
                }
            } else {
                // found - so its hidden, now show it
                //console.info("showing " + moduleId);
                content.slideDown();
                header.removeClass("NoPrint");
                knob.removeClass(Beacon.ModuleHeader.plusIcon).addClass(Beacon.ModuleHeader.minusIcon);
                btn.attr('aria-expanded', 'true');
                visibleModuleIds.push(moduleId);
                hiddenModuleIds = $.grep(hiddenModuleIds, function (item, index) { return item !== moduleId });
            }
            Beacon.ModuleHeader.saveHiddenModulesToCookie(hiddenModuleIds);
            Beacon.ModuleHeader.saveVisibleModulesToCookie(visibleModuleIds);

        } else {
            // generic collapsable item
            if (content.is(":visible")) {
                content.slideUp();
                btn.attr('aria-expanded', 'false');
                knob.removeClass(Beacon.ModuleHeader.minusIcon).addClass(Beacon.ModuleHeader.plusIcon);
                //todo
            } else {
                content.slideDown();
                btn.attr('aria-expanded', 'true');
                knob.removeClass(Beacon.ModuleHeader.plusIcon).addClass(Beacon.ModuleHeader.minusIcon);
            }
        }
        return false;
    },

    processInitialCookie: function () {

        var moduleIds = Beacon.ModuleHeader.getHiddenModulesFromCookie();
        $(moduleIds).each(function () {
            var moduleId = this;
            $("[MODULEID=" + moduleId + "] .toggle-collapse-icon")
                .removeClass(Beacon.ModuleHeader.minusIcon)
                .addClass(Beacon.ModuleHeader.plusIcon);

            $("[MODULEID=" + moduleId + "].module-header")
                .addClass("NoPrint");

            $("[MODULEID = " + moduleId + "] .toggle-collapse")
                .attr('aria-expanded', 'false');
        });


        var visibleModuleIds = Beacon.ModuleHeader.getVisibleModulesFromCookie();
        $(visibleModuleIds).each(function () {
            var moduleId = this;
            $("[MODULEID=" + moduleId + "] .toggle-collapse-icon")
                .removeClass(Beacon.ModuleHeader.plusIcon)
                .addClass(Beacon.ModuleHeader.minusIcon);

            $("[MODULEID=" + moduleId + "].module-header")
                .addClass("NoPrint");

            $("[MODULEID = " + moduleId + "] .toggle-collapse")
                .attr('aria-expanded', 'true');
        });

    },

    getHiddenModulesFromCookie: function () {
        var moduleList = $.cookie("MODULES" + mapConfig.AppId);
        if (moduleList) {
            return moduleList.split("|");
        } else {
            return [];
        }
    },

    saveHiddenModulesToCookie: function (moduleList) {

        if (moduleList) {
            $.cookie("MODULES" + mapConfig.AppId, moduleList.join("|"), { expires: 60 });
        } else {
            $.cookie("MODULES" + mapConfig.AppId, null);
        }
    },

    getVisibleModulesFromCookie: function () {
        var moduleList = $.cookie("MODULESVISIBILE" + mapConfig.AppId);
        if (moduleList) {
            return moduleList.split("|");
        } else {
            return [];
        }
    },

    saveVisibleModulesToCookie: function (moduleList) {

        if (moduleList) {
            $.cookie("MODULESVISIBILE" + mapConfig.AppId, moduleList.join("|"), { expires: 60 });
        } else {
            $.cookie("MODULESVISIBILE" + mapConfig.AppId, null);
        }
    },

    
    CLASS_NAME: "Beacon.ModuleHeader"
};



Beacon.Utils = {

    // Converts a utc date time string (ISO-8601 standard), to a local date time
    utcDTStringToLocalDTString: function (utcDTString) {

        if (utcDTString) {
            var localDT = new Date(utcDTString);
            if (localDT)
                return localDT.toLocaleString();
        }

        return "[Unknown]";
    },

    validateEmail: function (email) {
        var regEx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regEx.test(email);
    },


    CLASS_NAME: 'Beacon.Utils'

};


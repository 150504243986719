
Beacon.Ping = {

    timerId: null,
    interval: 887, // in seconds -- 30 minutes is the lifespan of sessionstate -- refresh it about 1/2 way through

    init: function () {
            this.timerId = setInterval(this.onTimer, this.interval * 1000);
    },

    onTimer: function () {
        // ping - to keep session alive
        Beacon.API.Ping();
    },

    CLASS_NAME: "Beacon.Ping"
};


Beacon.WCAG = {

    initialize: function () {
        //add spacebar support for ExpandCollapse elements
        $(".expandCollapseIcon").each(function () {
            Beacon.WCAG.addSpacebarSupport($(this));
            //add aria-expanded label support for expandCollapseIcon
            if ($(this).hasClass("icon-plus-squared-alt")) {
                $(this).attr('aria-expanded', 'false');
            }
            $(this).click(function () {
                if ($(this).hasClass("icon-plus-squared-alt")) {
                    $(this).attr('aria-expanded', 'true');
                }
                else {
                    $(this).attr('aria-expanded', 'false');
                }
            });
        });
        // end add spacebar support 


        this.addPauseAnimation();
        this.addButtonPolyfill();
        this.fixTables_Presentation();
        this.fixTables_Data();
        
        this.HeadingsandLabels2_4_6.updateRowHeaders('.tabular-data-two-column td:nth-child(1)');
        this.HeadingsandLabels2_4_6.updateRowHeaders('.salesSearchTable td:nth-child(1)');
        this.HeadingsandLabels2_4_6.updateRowHeaders('.compSearchTable td:nth-child(2)');
        this.HeadingsandLabels2_4_6.updateColHeaders('.compSearchTable');
     //   this.HeadingsandLabels2_4_6.updateColHeaders('.compSearchTableOne');
     //   this.HeadingsandLabels2_4_6.updateRowHeaders('.compSearchTableOne td:nth-child(2)');
     //   this.HeadingsandLabels2_4_6.updateColHeaders('.compSearchTableTwo');
     //   this.HeadingsandLabels2_4_6.updateRowHeaders('.compSearchTableTwo td:nth-child(2)');
     //   this.HeadingsandLabels2_4_6.updateColHeaders('.compSearchTableThree');
     //   this.HeadingsandLabels2_4_6.updateRowHeaders('.compSearchTableThree td:nth-child(2)');
        this.HeadingsandLabels2_4_6.updateColHeaders('.salesSearchTable');

        this.restructureSearchModules();
        this.restructureExceptionalSearchModules();
        this.fixCompSearches();
    },
/**
* Wires up a link button to expand or collapse a list of div's with one button click.
* @param {string[]} elementIds array with the div id's we are toggling on or off
* @param {string} text sometimes we pass in additional text to toggle.
* @param {string} buttonID (optional) The ID of the control element, that is, the button that toggles expand/collapse
*/
    ExpandCollapse: function (elementIds,text,buttonID) {
        var elementId = "";
        //sometimes some rouge developer might call this function by passing in a single string elementId rather than an array.
        if (Array.isArray(elementIds)) {
            elementId = elementIds.shift();
            while (elementIds.length >= 1) {   
                    Beacon.WCAG.ExpandCollapse(elementIds,text);
                }
        } else {
            //not array, just a single string value;
            elementId = elementIds;
        }
        var div = document.getElementById(elementId);
        if (!buttonID) {
            buttonID = 'btn' + elementId;
        }
        var b = $('#' + buttonID);
        if (div.style.display === "none") {
            div.style.display = "inline";
            b.toggleClass("icon-minus-squared-alt").toggleClass("icon-plus-squared-alt");
            //I don't know why I would ever want to pass in an different text value, but that's the way some of them were ~20200122 jm
            b.text(text?"Hide " + text:b.text().replace("Show","Hide"));
            }
        else {
            b.toggleClass("icon-plus-squared-alt").toggleClass("icon-minus-squared-alt");
            div.style.display = "none";
            b.text(text ? "Show " + text : b.text().replace("Hide", "Show"));
            }
    },

    addSpacebarSupport: function ($btn) {
        $btn.keydown(function (e) {
            if (e.keyCode === Beacon.WCAG.keyCode.SPACE) {
                e.preventDefault();
                e.target.click();
            }
        });
    },
    animationStatus: 1
     ,

    keyCode: {
        RETURN: 13,
        SPACE: 32,
        PAGEUP: 33,
        PAGEDOWN: 34,
        END: 35,
        HOME: 36,
        LEFT: 37,
        UP: 38,
        RIGHT: 39,
        DOWN: 40,
        SLASH: 191,
        ESCAPE: 27
    },
    addPauseAnimation: function () {
        var pauseAnimation = function () {

            Beacon.WCAG.animationStatus = 1 - Beacon.WCAG.animationStatus;
         
            if (Beacon.WCAG.animationStatus === 0) {
                $(".load-bar").children().each(function () {
                    w = ($(this).width());
                    l = ($(this).css("left"));
                    $(this).css("width", w);
                    $(this).css("left", l);
                    $(this).css("animation", 'none');
                });
            }
            else {
                $(".load-bar").children().each(function () {
                    ($(this).removeAttr("style"));
                });
            }
        };
        if (Beacon.WCAG.animationStatus == 0) {
            Beacon.WCAG.animationStatus = 1;
            pauseAnimation();

        }

        $(document).keydown(function (e) {
            if (event.keyCode === Beacon.WCAG.keyCode.ESCAPE) {
                e.preventDefault();
                pauseAnimation();
            }
        });
        
       },
    addButtonPolyfill: function () {
        // POLYFILL - allow spacebar to activate links that look like buttons
        //          - it won't mess with links that already have a role defined

        $("a.btn").not("[role]").keydown(function (e) {
            if (e.keyCode === Beacon.WCAG.keyCode.SPACE) {
                e.preventDefault();
                e.target.click();
            }
        }).attr('role', 'button');
    },

    fixTables_Presentation: function () {

        // two-col tables - presentation
        $("table.tabular-data-two-column").not("[role]").attr("role", "presentation");

        // tables with only one row and no thead (assume its just for display)
        $("table").not("[role]").has("tbody:only-child > tr:only-child").attr("role", "presentation");

        // tables with only one column and no thead
        $("table").not("[role]").has("tbody:only-child > tr:first > td:only-child").attr("role", "presentation");

    },

    HeadingsandLabels2_4_6:
    {
        //remove the presentation role
        
        updateColHeaders: function (tableClass) {
            
            $(tableClass).attr('role', 'table');
            if (($(tableClass).has('thead')).length === 0) {
                $(tableClass + ' tr:first-child').children('td,th').replaceWith(function (i, html) {
                    if (html === '&nbsp;') {
                        return '<td scope="row">&nbsp;</td>';
                    } else if ($(this).text().trim() === '') {
                        return '<td scope="row"></td>';
                    } else {
                        return '<th scope="row">' + html + '</th>';
                    }
                });
                var headerRow = $(tableClass + ' tr:first-child');
                var tc = $(tableClass);
                headerRow.each(function (i, element) {
                    $(tc[i]).prepend($('<thead>').append(headerRow[i]));
                });
              
            }
        },
        updateRowHeaders: function (className) {
        

            $(className).replaceWith(function (i, html) {
                if (html === '&nbsp;') {
                    return '<td scope="row">&nbsp;</td>';
                } else if ($(this).text().trim() === '') {
                    return '<td scope="row"></td>';
                } else {
                    return '<th scope="row">' + html + '</th>';
                }
            });

        }
    },

    fixTables_Data: function () {

        // tables w/o caption
        var dataTables = $("table[role!='presentation']").not(":has(caption)");
        dataTables.each(function () {
            var moduleTitle = $(this).parents("section").find("div.title").text();
            if (moduleTitle) {
                var caption = $("<caption></caption>");
                caption.text(moduleTitle).addClass("sr-only");
                $(this).append(caption);
            }
        });

    },

    visualLintRules: function () {

        // table rows without th[scope=row] in the data row
        $("table[role!=presentation] > tbody > tr").not(":has(th[scope=row])").each(function (index) {
            // figure out which cell is 'supposed' to be the header of the row
            var rowHeaderIndex = $(this).parents('table').first().attr('rowheaderindex');
            if (rowHeaderIndex === undefined) {
                rowHeaderIndex = 0;
            }

            // if that cell is blank, then it should not be a row header
            if ($($(this).children[rowHeaderIndex]).text().trim() !== '') {
                $(this).css('border', '2px solid red').attr("title", "WCAG ERROR: table rows don't have a <th scope=row>, or table needs to set role=presentation");
            }
        });

        // WCAG - nested tables flagged
        $("table[role!='presentation'] table[role!='presentation']").css('border', '2px solid red').attr("title", "WCAG ERROR: nested tables - if outer table is for layout set role=presentation");

    },

    fixCompSearches: function () {
        $('.compSearchTable,.salesSearchTable,.advanced-search-table').each(function (tableIdx, table) {
            $(table).find('tbody').find('tr').each(function (rowIdx, row) {
                // Zeroth, if the row is empty or only has 1 cell, then this is a spurious blank row
                if ($(row).children('td,th').length < 2) {
                    return true;
                }

                // First, get the criterion cell
                const criterionCell = Beacon.WCAG.getCriterionCell(table, row);
                if (!criterionCell) {
                    return true;
                }

                // Then get the category cell text
                const categoryLabel = Beacon.WCAG.getCategoryLabel(table, row);
                if (!categoryLabel) {
                    return true;
                }

                const tableID = `search_table_${tableIdx}`;
                const idDomain = `${tableID}_${rowIdx}_`;

                const useCheckbox = Beacon.WCAG.getUseCheckbox(table, row);
                Beacon.WCAG.fixUseCheckbox(idDomain, categoryLabel, useCheckbox);

                // Then determine what type of input this row is

                // Sale date compound input
                if (Beacon.WCAG.isSaleDateCompoundInput(criterionCell)) {
                    Beacon.WCAG.fixSaleDateCompoundInput(idDomain, categoryLabel, criterionCell);
                    return true;
                }

                // Distance from subject compound input
                if (Beacon.WCAG.isDistanceFromSubjectCompoundInput(criterionCell)) {
                    Beacon.WCAG.fixDistanceFromSubjectCompoundInput(idDomain, categoryLabel, criterionCell);
                    return true;
                }

                // Low/high input range
                if (Beacon.WCAG.isLowHighInputRange(criterionCell)) {
                    Beacon.WCAG.fixLowHighInputRange(idDomain, categoryLabel, criterionCell);
                    return true;
                }

                // Low/high input range, with dropdown for selecting units
                if (Beacon.WCAG.isLowHighRangeWithUnit(criterionCell)) {
                    Beacon.WCAG.fixLowHighWithUnitDropdown(idDomain, categoryLabel, criterionCell);
                    return true;
                }

                // Single list box
                if (Beacon.WCAG.isSingleListBox(criterionCell)) {
                    Beacon.WCAG.fixSingleListBoxInput(idDomain, categoryLabel, criterionCell);
                    return true;
                }

                // Single text box
                if (Beacon.WCAG.isSingleTextBox(criterionCell)) {
                    Beacon.WCAG.fixSingleTextBoxInput(idDomain, categoryLabel, criterionCell);
                    return true;
                }

                // Single dropdown
                if (Beacon.WCAG.isSingleDropdown(criterionCell)) {
                    Beacon.WCAG.fixSingleListBoxInput(idDomain, categoryLabel, criterionCell);
                    return true;
                }

                // Single check box
                if (Beacon.WCAG.isSingleCheckbox(criterionCell)) {
                    Beacon.WCAG.fixSingleCheckboxInput(idDomain, categoryLabel, criterionCell);
                    return true;
                }

                // Radio buttons
                if (Beacon.WCAG.isRadioInput(criterionCell)) {
                    Beacon.WCAG.fixRadioInput(idDomain, categoryLabel, criterionCell);
                    return true;
                }

                // Subdivision typeahead
                if (Beacon.WCAG.isSubdivisionTypeaheadInput(criterionCell)) {
                    Beacon.WCAG.fixSubdivisionTypeaheadInput(idDomain, categoryLabel, criterionCell);
                    return true;
                }
            });
        });
    },
    getUseCheckbox: function (table, row) {
        const hasUseColumn = $(table).find('thead > tr:first-child').find('td,th').eq(0).text().trim().toLowerCase() === 'use';

        if (!hasUseColumn) {
            return null;
        }

        const useCheckbox = $(row).find('td,th').eq(0).find('input[type="checkbox"]');
        if (useCheckbox.length < 1) {
            return null;
        }
        return useCheckbox;
    },
    getCriterionCell: function (table, row) {
        const hasUseColumn = $(table).find('thead > tr:first-child').find('td,th').eq(0).text().trim().toLowerCase() === 'use';

        if (hasUseColumn && $(row).find('td,th').length >= 3) {
            return $(row).find('td,th')[2];
        } else if (!hasUseColumn && $(row).find('td,th').length >= 2) {
            return $(row).find('td,th')[1];
        } else {
            return null;
        }
    },
    getCategoryLabel: function (table, row) {
        const categoryCell = Beacon.WCAG.getCategoryCell(table, row);
        if (!categoryCell) {
            return null;
        }

        if ($(categoryCell).children('span,label,strong').length !== 1) {
            return null;
        }

        return $(categoryCell).children('span,label,strong').eq(0);
    },
    getCategoryCell: function (table, row) {
        const hasUseColumn = $(table).find('thead > tr:first-child').find('td,th').eq(0).text().trim().toLowerCase() === 'use';

        if (hasUseColumn && $(row).find('td,th').length >= 2) {
            return $(row).find('td,th')[1];
        } else if (!hasUseColumn && $(row).find('td,th').length >= 2) {
            return $(row).find('td,th')[0];
        } else {
            return null;
        }
    },
    isSaleDateCompoundInput: function (criterionCell) {
        if ($(criterionCell).find('input,select').length !== 5) {
            return false;
        }
        if ($(criterionCell).find('input[type="radio"]').length !== 2) {
            return false;
        }
        if ($(criterionCell).find('select').length !== 1) {
            return false;
        }
        if ($(criterionCell).find('input[type="date"],input[type="text"]').length !== 2) {
            return false;
        }
        // Look for the words "Low" and "High"
        if ($(criterionCell).text().toLowerCase().indexOf('low') < 0 && $(criterionCell).text().toLowerCase().indexOf('from') < 0) {
            return false;
        }
        if ($(criterionCell).text().toLowerCase().indexOf('high') < 0 && $(criterionCell).text().toLowerCase().indexOf('to') < 0) {
            return false;
        }
        return true;
    },
    isDistanceFromSubjectCompoundInput: function (criterionCell) {
        if ($(criterionCell).find('input').length !== 1) {
            return false;
        }
        if ($(criterionCell).find('select').length !== 1) {
            return false;
        }
        if ($(criterionCell).find('select').find('option[value="mi"]').length !== 1) {
            return false;
        }
        return true;
    },
    isLowHighInputRange: function (criterionCell) {
        if ($(criterionCell).find('input,select,textarea').length !== 2) {
            return false;
        }
        // Look for the words "Low" and "High"
        if ($(criterionCell).text().toLowerCase().indexOf('low') < 0) {
            return false;
        }
        if ($(criterionCell).text().toLowerCase().indexOf('high') < 0) {
            return false;
        }
        return true;
    },
    isLowHighRangeWithUnit: function (criterionCell) {
        if ($(criterionCell).find('input,select,textarea').length !== 3) {
            return false;
        }
        if ($(criterionCell).find('input').length !== 2) {
            return false;
        }
        if ($(criterionCell).find('select').length !== 1) {
            return false;
        }
        // Look for the words "Low" and "High"
        if ($(criterionCell).text().toLowerCase().indexOf('low') < 0) {
            return false;
        }
        if ($(criterionCell).text().toLowerCase().indexOf('high') < 0) {
            return false;
        }
        return true;
    },
    isSingleListBox: function (criterionCell) {
        if ($(criterionCell).find('input,select').length !== 1) {
            return false;
        }
        if ($(criterionCell).find('select[multiple="multiple"]').length !== 1) {
            return false;
        }
        return true;
    },
    isSingleTextBox: function (criterionCell) {
        if ($(criterionCell).find('input,select,textarea').length !== 1) {
            return false;
        }
        if ($(criterionCell).find('input[type="text"],textarea').length !== 1) {
            return false;
        }
        if ($(criterionCell).find('input[type="hidden"]').length !== 0) {
            return false;
        }
        return true;
    },
    isSingleDropdown: function (criterionCell) {
        if ($(criterionCell).find('input,select').length !== 1) {
            return false;
        }
        if ($(criterionCell).find('select:not([multiple])').length !== 1) {
            return false;
        }
        return true;
    },
    isSingleCheckbox: function (criterionCell) {
        if ($(criterionCell).find('input,select,textarea').length !== 1) {
            return false;
        }
        if ($(criterionCell).find('input[type="checkbox"]').length !== 1) {
            return false;
        }
        return true;
    },
    isRadioInput: function (criterionCell) {
        if ($(criterionCell).find('input:not([type="radio"])').length > 0) {
            return false;
        }
        if ($(criterionCell).find('input[type="radio"]').length < 1) {
            return false;
        }
        return true;
    },
    isSubdivisionTypeaheadInput: function (criterionCell) {
        if ($(criterionCell).find('input[type="hidden"]').length !== 1) {
            return false;
        }
        if ($(criterionCell).find('input[type="text"]').length !== 1) {
            return false;
        }
        return true;
    },
    fixUseCheckbox: function (idDomain, categoryLabel, useCheckbox) {
        if (!useCheckbox) {
            return;
        }
        if ($(categoryLabel).prop('tagName').toLowerCase() === 'label' && $(categoryLabel).attr('for') !== $(useCheckbox).attr('id')) {
            $(categoryLabel).attr('for', $(useCheckbox).attr('id'));
        } else if ($(categoryLabel).prop('tagName').toLowerCase() === 'span') {
            if ($(categoryLabel).attr('id')) {
                $(categoryLabel).attr('id', `${idDomain}useCheckboxLabel`);
            }
            const useCheckboxLabelID = $(categoryLabel).attr('id');

            $(useCheckbox).attr('aria-labelledby', useCheckboxLabelID);
        }
    },
    fixSaleDateCompoundInput: function (idDomain, categoryLabel, criterionCell) {
        const categoryText = $(categoryLabel).text().trim();

        const superLegendID = `${idDomain}superLegend`;
        const listLegendID = `${idDomain}listLegend`;
        const listLabelID = `${idDomain}listLabel`;
        const rangeLegendID = `${idDomain}rangeLegend`;
        const salesLabelID = `${idDomain}salesLabel`;
        const fromLabelID = `${idDomain}fromLabel`;
        const toLabelID = `${idDomain}toLabel`;

        const superFieldset = $('<fieldset></fieldset>');
        const superLegend = $(`<legend class="sr-only" id="${superLegendID}">${categoryText}</legend>`);
        const rbDateList = $(criterionCell).find('input[type="radio"]').eq(0).detach();
        const listFieldset = $('<fieldset class="inline-fieldset"></fieldset>');
        const listLegend = $(`<legend class="sr-only" id="${listLegendID}">Choose sale date from list</legend>`);
        const listLabel = $(`<span id="${listLabelID}">Sales within the past </span>`);
        const dateList = $(criterionCell).find('select').detach();
        const lineBreak = $('<br/>');
        const rbDateRange = $(criterionCell).find('input[type="radio"]').detach();
        const rangeFieldset = $('<fieldset class="inline-fieldset"></fieldset>');
        const rangeLegend = $(`<legend class="sr-only" id="${rangeLegendID}">Set sale date range</legend>`);
        const salesLabel = $(`<span id="${salesLabelID}">Sales </span>`);
        const fromLabel = $(`<span id="${fromLabelID}">From </span>`);
        const fromDate = $(criterionCell).find('input[type="date"],input[type="text"]').eq(0).detach();
        const toLabel = $(`<span id="${toLabelID}"> To </span>`);
        const toDate = $(criterionCell).find('input[type="date"],input[type="text"]').detach();

        $(rbDateList).attr('aria-labelledby', listLegendID);
        $(rbDateList).removeAttr('aria-label');
        $(dateList).attr('aria-labelledby', `${listLabelID} ${$(dateList).attr('id')}`);
        $(dateList).removeAttr('aria-label');
        $(rbDateRange).attr('aria-labelledby', rangeLegendID);
        $(rbDateRange).removeAttr('aria-label');
        $(fromDate).attr('aria-labelledby', `${salesLabelID} ${fromLabelID}`)
        $(fromDate).removeAttr('aria-label');
        $(toDate).attr('aria-labelledby', `${salesLabelID} ${toLabelID}`);
        $(toDate).removeAttr('aria-label');
        
        $(listFieldset).append(listLegend);
        $(listFieldset).append(listLabel);
        $(listFieldset).append(dateList);

        $(rangeFieldset).append(rangeLegend);
        $(rangeFieldset).append(salesLabel);
        $(rangeFieldset).append(fromLabel);
        $(rangeFieldset).append(fromDate);
        $(rangeFieldset).append(toLabel);
        $(rangeFieldset).append(toDate);

        $(superFieldset).append(superLegend);
        $(superFieldset).append(rbDateList);
        $(superFieldset).append(listFieldset);
        $(superFieldset).append(lineBreak);
        $(superFieldset).append(rbDateRange);
        $(superFieldset).append(rangeFieldset);

        $(criterionCell).empty();
        $(criterionCell).append(superFieldset);
    },
    fixDistanceFromSubjectCompoundInput: function (idDomain, categoryLabel, criterionCell) {
        const legendID = `${idDomain}legend`;

        const fieldset = $('<fieldset></fieldset>');
        const legend = $(`<legend class="sr-only" id="${legendID}">${$(categoryLabel).text().trim()}</legend>`);
        const magnitude = $(criterionCell).find('input').detach();
        const unit = $(criterionCell).find('select').detach();

        $(magnitude).attr('aria-labelledby', `${legendID} ${$(magnitude).attr('id')} ${$(unit).attr('id')}`);
        $(magnitude).removeAttr('aria-label');
        $(unit).attr('aria-labelledby', `${legendID} ${$(magnitude).attr('id')} ${$(unit).attr('id')}`);
        $(unit).removeAttr('aria-label');
        
        $(fieldset).append(legend);
        $(fieldset).append(magnitude);
        $(fieldset).append(unit);

        $(criterionCell).empty();
        $(criterionCell).append(fieldset);
    },
    fixLowHighInputRange: function (idDomain, categoryLabel, criterionCell) {
        const legendID = `${idDomain}legend`;
        const lowSpanID = `${idDomain}low`;
        const highSpanID = `${idDomain}high`;

        const unitNodes = Beacon.WCAG.findUnitTextNodes($(criterionCell).find('input').parent().contents());

        const fieldset = $('<fieldset></fieldset>');
        const legend = $(`<legend class="sr-only" id="${legendID}">${$(categoryLabel).text().trim()}</legend>`);
        const lowSpan = $(`<span id="${lowSpanID}">Low </span>`);
        const lowInput = $(criterionCell).find('input').eq(0).detach();
        const highSpan = $(`<span id="${highSpanID}"> High </span>`);
        const highInput = $(criterionCell).find('input').detach();

        $(lowInput).attr('aria-labelledby', `${legendID} ${lowSpanID}`);
        $(lowInput).removeAttr('aria-label');
        $(highInput).attr('aria-labelledby', `${legendID} ${highSpanID}`);
        $(highInput).removeAttr('aria-label');

        $(fieldset).append(legend);
        $(fieldset).append(lowSpan);
        $(fieldset).append(lowInput);
        $(fieldset).append(highSpan);
        $(fieldset).append(highInput);
                
        if (unitNodes.length > 0) {
            const unitID = `${idDomain}unit`;
            const unitLabel = $(`<span id="${unitID}"></span>`);
            for (let unitNode of unitNodes) {
                $(unitLabel).append(unitNode);
            }
            $(lowInput).attr('aria-labelledby', `${legendID} ${lowSpanID} ${unitID}`);
            $(highInput).attr('aria-labelledby', `${legendID} ${highSpanID} ${unitID}`);

            $(fieldset).append(unitLabel);
        }

        $(criterionCell).empty();
        $(criterionCell).append(fieldset);
    },
    fixLowHighWithUnitDropdown: function (idDomain, categoryLabel, criterionCell) {
        const legendID = `${idDomain}legend`;
        const lowSpanID = `${idDomain}low`;
        const highSpanID = `${idDomain}high`;

        const fieldset = $('<fieldset></fieldset>');
        const legend = $(`<legend class="sr-only" id="${legendID}">${$(categoryLabel).text().trim()}</legend>`);
        const lowSpan = $(`<span id="${lowSpanID}">Low </span>`);
        const lowInput = $(criterionCell).find('input').eq(0).detach();
        const highSpan = $(`<span id="${highSpanID}"> High </span>`);
        const highInput = $(criterionCell).find('input').detach();
        const unitInput = $(criterionCell).find('select').detach();
        
        $(lowInput).attr('aria-labelledby', `${legendID} ${lowSpanID} ${$(unitInput).attr('id')}`);
        $(lowInput).removeAttr('aria-label');
        $(highInput).attr('aria-labelledby', `${legendID} ${highSpanID} ${$(unitInput).attr('id')}`);
        $(highInput).removeAttr('aria-label');
        $(unitInput).attr('aria-label', 'Select measurement unit');

        $(fieldset).append(legend);
        $(fieldset).append(lowSpan);
        $(fieldset).append(lowInput);
        $(fieldset).append(highSpan);
        $(fieldset).append(highInput);
        $(fieldset).append('<span>&nbsp;</span>');
        $(fieldset).append(unitInput);

        $(criterionCell).empty();
        $(criterionCell).append(fieldset);
    },
    fixSingleListBoxInput: function (idDomain, categoryLabel, criterionCell) {
        // assign an ID to the label in the category cell
        if (!$(categoryLabel).attr('id')) {
            $(categoryLabel).attr('id', `${idDomain}categoryLabel`)
        }
        const categoryID = $(categoryLabel).attr('id');

        const instructionNodes = Beacon.WCAG.findInstructionText($(criterionCell).find('input,select').parent().contents());
        const instructionID = `${idDomain}instructions`;
        const lineBreak = $('<br/>');
        const instructionsLabel = $(`<span id="${instructionID}"></span>`);
        for (let instructionNode of instructionNodes) {
            $(instructionsLabel).append($(instructionNode).detach());
        }

        const input = $(criterionCell).find('input,select').detach();
        $(input).removeAttr('aria-label');
        $(input).attr('aria-labelledby', categoryID);

        $(criterionCell).empty();
        $(criterionCell).append(input);

        if (instructionNodes.length > 0) {
            $(criterionCell).append(lineBreak);
            $(criterionCell).append(instructionsLabel);
            $(input).attr('aria-describedby', instructionID);
        }
    },
    fixSingleTextBoxInput: function (idDomain, categoryLabel, criterionCell) {
        // assign an ID to the label in the category cell
        if (!$(categoryLabel).attr('id')) {
            $(categoryLabel).attr('id', `${idDomain}categoryLabel`)
        }
        const categoryID = $(categoryLabel).attr('id');

        const unitNodes = Beacon.WCAG.findUnitTextNodes($(criterionCell).find('input,select,textarea').parent().contents());
        const unitID = `${idDomain}unit`;
        const unitLabel = $(`<span id="${unitID}"></span>`);
        for (let unitNode of unitNodes) {
            $(unitLabel).append($(unitNode).detach());
        }

        const instructionNodes = Beacon.WCAG.findInstructionText($(criterionCell).find('input,select,textarea').parent().contents());
        const instructionID = `${idDomain}instructions`;
        const br = $('<br/>');
        const instructionsLabel = $(`<span id="${instructionID}"></span>`);
        for (let instructionNode of instructionNodes) {
            $(instructionsLabel).append($(instructionNode).detach());
        }

        const input = $(criterionCell).find('input,select,textarea').detach();

        $(input).removeAttr('aria-label');
        $(input).attr('aria-labelledby', `${categoryID}`);

        $(criterionCell).empty();

        $(criterionCell).append(input);
                
        if (unitNodes.length > 0) {
            $(criterionCell).append(unitLabel);
            $(input).attr('aria-labelledby', `${categoryID} ${$(input).attr('id')} ${unitID}`);
        }
                
        if (instructionNodes.length > 0) {
            $(criterionCell).append(br);
            $(criterionCell).append(instructionsLabel);
            $(input).attr('aria-describedby', instructionID);
        }
    },
    fixSingleCheckboxInput: function (idDomain, categoryLabel, criterionCell) {
        // we have to find the label
        const labelText = $(criterionCell)
            .find('input[type="checkbox"]')
            .parents('fieldset,label,td,th')
            .first()
            .contents()
            .filter((idx, element) => {
                return !$(element).hasClass('sr-only');
            })
            .text()
            .trim();

        const fieldset = $('<fieldset></fieldset>');
        const legend = $(`<legend class="sr-only">${$(categoryLabel).text().trim()}</legend>`);
        const label = $(`<label>${labelText}</label>`);

        const checkbox = $(criterionCell).find('input[type="checkbox"]').detach();
        $(checkbox).removeAttr('aria-label');
        $(checkbox).removeAttr('aria-labelledby');

        $(label).prepend(checkbox);

        $(fieldset).append(legend);
        $(fieldset).append(label);

        $(criterionCell).empty();
        $(criterionCell).append(fieldset);
    },
    fixRadioInput: function (idDomain, categoryLabel, criterionCell) {
        const fieldset = $('<fieldset></fieldset>');
        const legend = $(`<legend class="sr-only">${$(categoryLabel).text().trim()}</legend>`);
        const neededContent = $(criterionCell).find('input,label,br').detach();

        $(neededContent).removeAttr('aria-label');
        $(neededContent).removeAttr('aria-labelledby');

        $(fieldset).append(legend);
        $(fieldset).append(neededContent);

        $(criterionCell).empty();
        $(criterionCell).append(fieldset);
    },
    fixSubdivisionTypeaheadInput: function (idDomain, categoryLabel, criterionCell) {
        // Since this input is modified by a script in order to create the typeahead, we'll play it
        // safe and set the 'for' attribute on the label rather than the 'aria-labelledby'
        // attribute on the input
        const inputID = $(criterionCell).find('input[type="text"]').attr('id');
        $(categoryLabel).attr('for', inputID);
    },
    findInstructionText: function (cellContents) {
        /*const cellContents = $(criterionCell).contents();*/

        // find the index of the last input
        const lastInput = $(cellContents).filter('input,select').last();
        const lastInputIdx = $(cellContents).index(lastInput);

        // find the index of the last <br/>, if there is one
        const lastBr = $(cellContents).filter('br').last();
        const lastBrIdx = $(cellContents).index(lastBr);

        if ((lastBrIdx < lastInputIdx) || ((lastBrIdx + 1) >= $(cellContents).length)) {
            return [];
        }

        const postBrElements = $(cellContents).slice(lastBrIdx + 1);
        return postBrElements.filter(function (idx, element) {
            return $(element).text().trim() !== '';
        }).toArray();
    },
    findUnitTextNodes: function (cellContents) {
        // The unit should be a text ndoe that comes right after the last input
        //const cellContents = $(criterionCell).contents();

        // find the index of the last input
        const lastInput = $(cellContents).filter('input,select').last();
        const lastInputIdx = $(cellContents).index(lastInput);

        // find the index of the last <br/>, if there is one
        const lastBr = $(cellContents).filter('br').last();
        const lastBrIdx = $(cellContents).index(lastBr);

        if (lastBrIdx > (lastInputIdx + 1)) {
            return $($(cellContents).slice(lastInputIdx + 1, lastBrIdx)).filter(function (idx, element) {
                return $(element).text().trim() !== '';
            }).toArray();
        } else {
            return $($(cellContents).slice(lastInputIdx + 1)).filter(function (idx, element) {
                return $(element).text().trim() !== '';
            }).toArray();
        }
    },

    restructureExceptionalSearchModules: function () {
        $('section').each(function (idx, section) {
            const repairContents = $(section).find('fieldset.wcag-repair');
            if (repairContents.length < 1) {
                return true;
            }

            const moduleTitle = $(section).find('.title');
            const searchButtons = $(section).find('.btn.btn-primary').has('.glyphicon.glyphicon-search');
            $(searchButtons).attr('aria-labelledby', $(moduleTitle).attr('id'));

            $(repairContents).prepend($(`<legend class="sr-only">${$(moduleTitle).text().trim()}</legend>`));
        });
    },
    restructureSearchModules: function () {
        $('section').each(function (idx, section) {
            /* The majority of cases are simple: 1 button in the whole module, which is a search button,
             * and one .help-block element, which is not associated with anything. In those cases, feel
             * free to associate them.
             * 
             * Conditions:
             * - 1 .help-block element in the whole module
             * - That help-block element contains text
             * - 1 button (or input[type="button"]) in the whole module
             * - That button has a search glyphicon
             * - That button does not have an aria-labelledby attribute
             */

            Beacon.WCAG.fixSearchButtonFieldset(section);
            Beacon.WCAG.fixSearchButtonLabel(section);
            Beacon.WCAG.fixSingleInputLabel(section);
            Beacon.WCAG.fixHelpBlock(section);
            Beacon.WCAG.addTypeaheadInstructios(section);
        });
    },
    hasOneSearchButton: function (section) {
        if ($(section).find('.btn.btn-primary').has('.glyphicon.glyphicon-search').length !== 1) {
            return false;
        }
        return true;
    },
    fixSearchButtonFieldset: function (section) {
        const searchButton = $(section).find('.btn.btn-primary').has('.glyphicon.glyphicon-search');
        if (searchButton.length !== 1) {
            return;
        }
        if ($(searchButton).parentsUntil('.module-content', 'fieldset').length > 0) {
            return;
        }

        const moduleTitle = $(section).find('.title');
        if ($(moduleTitle).text().trim() === '') {
            return;
        }

        const newFieldset = $('<fieldset></fieldset>');
        const newLegend = $(`<legend class="sr-only">${$(moduleTitle).text().trim()}</legend>`);
        const moduleContent = $(section).find('.module-content').contents();

        $(newFieldset).append(newLegend);
        $(newFieldset).append(moduleContent);

        $(section).find('.module-content').append(newFieldset);
    },
    fixSearchButtonLabel: function (section) {
        const moduleTitle = $(section).find('.title');
        const searchButton = $(section).find('.btn.btn-primary').has('.glyphicon.glyphicon-search');
        if (searchButton.length !== 1) {
            return;
        }

        if ($(searchButton).attr('aria-labelledby') === $(moduleTitle).attr('id')) {
            return;
        }

        $(searchButton).attr('aria-labelledby', `${$(moduleTitle).attr('id')} ${$(searchButton).attr('id')}`);
        $(searchButton).removeAttr('aria-label');
    },
    fixSingleInputLabel: function (section) {
        // Per a discussion referenced in a comment on BEAC-3879, if there is only 1 input in the module,
        // then the input can be labelled by the module's title. If there is more than 1 input, then
        // they will need to be supplied with their own unique labels.

        const input = $(section).find('input,select,textarea').not('[type="checkbox"]').not('[type="radio"]');
        if (input.length !== 1) {
            return;
        }

        const moduleTitle = $(section).find('.title');
        $(input).attr('aria-labelledby', $(moduleTitle).attr('id'));

        // get rid of any sr-only labels, if any
        $(section).find('.sr-only').removeAttr('for');
    },
    addTypeaheadInstructios: function (section) {
        $(section).find('span.twitter-typeahead > input[type="text"]').each(function (idx, input) {
            const descrID = `${$(input).attr('id')}_resultsDescription`;
            const resultsDescription = $(`<div id="${descrID}" class="sr-only">Options are available upon partial entry, use arrow keys to navigate available options. Use Search button to return all results that match your entry on a seperate page.</div>`);

            if ($(input).attr('aria-describedby') === undefined) {
                $(input).attr('aria-describedby', descrID);
            } else {
                $(input).attr('aria-describedby', `${$(input).attr('aria-describedby')} ${descrID}`);
            }
            
            $(section).append(resultsDescription);
        });
    },
    fixHelpBlock: function (section) {
        const helpBlock = $(section).find('.help-block');
        if (helpBlock.length !== 1) {
            return;
        }
        if ($(helpBlock).text().trim() === '') {
            return;
        }
        
        const input = $(section).find('input,select,textarea').not('[type="checkbox"]').not('[type="radio"]');
        const descrID = $(helpBlock).attr('id');
        if ($(input).attr('aria-describedby') === undefined) {
            $(input).attr('aria-describedby', descrID);
        } else {
            $(input).attr('aria-describedby', `${$(input).attr('aria-describedby')} ${descrID}`);
        }
    },

    CLASS_NAME: "Beacon.WCAG"
};




Beacon.PrintDialog = {

    loaded: false,

    defaultSettings: {
        paperSize: "8.5x11",
        mapQuality: 150,
        title: "",
        subtitle: "",
        author: "",
        showHeader: true,
        showTitleBar: true,
        showFooter: true,
        showOverview: true,
        showLegend: true,
        showScaleBar: true,
        showNorthArrow: true,
        showDetails: true,
        mapScale: "FIT"
    },

    settings: {},

    onPageLoad: function () {

        $("#btnToolbarPrint").click(Beacon.PrintDialog.print);
        $("#btnToolbarPrintSetup").click(Beacon.PrintDialog.printSetup);
        $("#btnToolbarBrowserPrint").click(Beacon.PrintDialog.printViaBrowser);
        //$("#btnToolbarBrowserPrint2").click(function () {
        //    $("BODY").addClass("print-compact-1");
        //   // window.print();
        //    //$("BODY").removeClass("print-compact-1");
        //});

        //$("#dropdownMenuPrintSettings").click(function (e) {
        //    console.info(e);
        //    //var layout = e.target.data.layout;
        //    e.preventDefault();
        //});



        Beacon.PrintDialog.loadDefaultSettings();

        Beacon.PrintDialog.loadDefaultPrintLayout();

    },


    initializeSettingsDialog: function () {

        if (Beacon.PrintDialog.loaded) { return; }

        Beacon.PrintDialog.bindSettingsToUi();

        $("#print_title").on("change keyup", function () {
            $("#preview_title").text($(this).val());
        });
        $("#print_subtitle").on("change keyup", function () {
            $("#preview_subtitle").text($(this).val());
        });
        $("#print_author").on("change keyup", function () {
            $("#preview_author").text($(this).val());
        });

        $("#print_showHeader").change(function () {
            //console.info($("#print_showHeader").val());
            //if ($("#print_showHeader").val()) {
            //    $("#preview_header_section").show();
            //} else {
            //    $("#preview_header_section").hide();
            //}
        });

        $("#btnPrintSetDefault").click(Beacon.PrintDialog.onSaveDefault);
        $("#btnPrintResetDefault").click(Beacon.PrintDialog.onResetToDefaults);

        $("#btnPrintPdf").click(Beacon.PrintDialog.print);

        $("#print_mapScale").on('change', function () {
            var scale = $("#print_mapScale").val();
            if (scale === "CUSTOM") {
                //sdc: This would not hide which is why the setTimeout is in there.  Probably a better way to do this but I've spent way too much time trying to figure it out already
                console.log('show custom');
                setTimeout(function () {
                    $("#print_customScaleDiv").show();
                }, 100);
            } else {
                console.log('scale: ' + scale);
                console.log('hide custom');
                //sdc: This would not hide which is why the setTimeout is in there.  Probably a better way to do this but I've spent way too much time trying to figure it out already
                setTimeout(function () {
                    $("#print_customScaleDiv").hide();
                }, 100);
            }
        });

        $("#print_paperSize").on('change', function () {
            var size = $("#print_paperSize").val();
            if (size === '17x22' || size === '22x17' || size === '22x34' || size === '34x22' || size === '24x24' || size === '24x36' || size === '36x24' || size === '32x32' || size === '34x22' || size === '22x34' || size === '36x36' || size === '36x48' || size === '48x36') {
                $("#print_mapQuality").val("96");
                Beacon.PrintDialog.settings.mapQuality = "96";
                $("#print_mapQuality").attr('disabled','');
                $("#print_mapQuality").attr('title', 'Not available for large format output');
            } else {
                $("#print_mapQuality").removeAttr('disabled');
                $("#print_mapQuality").removeAttr('title');
            }
        });
        $("#print_paperSize").change();

        Beacon.PrintDialog.loaded = true;

    },

    loadDefaultSettings: function () {

        // populate defaults
        $.extend(Beacon.PrintDialog.settings, Beacon.PrintDialog.defaultSettings);

        if (Beacon.localStorage) {
            var settings = Beacon.localStorage.getItem("PrintOptions");
            if (settings) {
                Beacon.PrintDialog.settings = JSON.parse(settings);
            }
        }
    },

    loadDefaultPrintLayout: function () {

        var layoutId = 1; //the default value

        if (Beacon.localStorage) {
            var settings = Beacon.localStorage.getItem("PrintLayout");
            if (settings) {
                layoutId = settings;
            }
        }

        layoutId = Beacon.PrintDialog.validatePrintLayoutId(layoutId);

        Beacon.PrintDialog.setPrintLayout(layoutId);
        Beacon.WCAG.addSpacebarSupport($(".main-toolbar #printSettingsDropdown a"));
        Beacon.WCAG.addSpacebarSupport($(".main-toolbar #shareDropDown a"));
        
        // magic css to keep section headers with contents, but only for modules < 320px high
        $(".page-center-pane section").each(function (idx, el) {
            var div = $(".module-content", el);
            var h = div.height();
            if (h < 320 && !$(el).attr('LAYOUT')) {  // also ignore photo and sketch modules, since their height isn't quite right at first
                $(el).addClass('avoid-page-break');
            }
        });

    },

    bindSettingsToUi: function () {

        Beacon.PrintDialog.bindControl("#print_title", Beacon.PrintDialog.settings, "title");
        Beacon.PrintDialog.bindControl("#print_subtitle", Beacon.PrintDialog.settings, "subtitle");
        Beacon.PrintDialog.bindControl("#print_author", Beacon.PrintDialog.settings, "author");

        Beacon.PrintDialog.bindControl("#print_showHeader", Beacon.PrintDialog.settings, "showHeader");
        Beacon.PrintDialog.bindControl("#print_showFooter", Beacon.PrintDialog.settings, "showFooter");
        Beacon.PrintDialog.bindControl("#print_showTitleBar", Beacon.PrintDialog.settings, "showTitleBar");
        Beacon.PrintDialog.bindControl("#print_showOverview", Beacon.PrintDialog.settings, "showOverview");
        Beacon.PrintDialog.bindControl("#print_showLegend", Beacon.PrintDialog.settings, "showLegend");
        Beacon.PrintDialog.bindControl("#print_showScaleBar", Beacon.PrintDialog.settings, "showScaleBar");
        Beacon.PrintDialog.bindControl("#print_showNorthArrow", Beacon.PrintDialog.settings, "showNorthArrow");
        Beacon.PrintDialog.bindControl("#print_showDetails", Beacon.PrintDialog.settings, "showDetails");

        Beacon.PrintDialog.bindControl("#print_paperSize", Beacon.PrintDialog.settings, "paperSize");
        Beacon.PrintDialog.bindControl("#print_mapQuality", Beacon.PrintDialog.settings, "mapQuality");
        Beacon.PrintDialog.bindControl("#print_mapScale", Beacon.PrintDialog.settings, "mapScale");
    },


    saveDefaultSettings: function () {
        if (Beacon.localStorage) {
            var mapScale = Beacon.PrintDialog.settings['mapScale'];

            //Only persist the mapScale as a default if it is FIT or CURRENT
            if (mapScale !== 'FIT' && mapScale !== 'CURRENT') {
                Beacon.PrintDialog.settings['mapScale'] = 'FIT';
            }

            Beacon.localStorage.setItem("PrintOptions", JSON.stringify(Beacon.PrintDialog.settings));
            $("#fbPrintSetDefault").show().delay(3000).fadeOut(500);
        }
    },

    onResetToDefaults: function () {
        // populate defaults
        //Beacon.PrintDialog.settings = {};
        //$.extend(Beacon.PrintDialog.settings, Beacon.PrintDialog.defaultSettings);

        // just remove the stored settings
        if (Beacon.localStorage) {
            Beacon.localStorage.removeItem("PrintOptions");
        }
    },

    //bindUiToSettings: function () {
    //    var s = Beacon.PrintDialog.settings;
    //    s.title = $("#print_title").val();
    //},

    onSaveDefault: function () {
        //Beacon.PrintDialog.bindUiToSettings();
        Beacon.PrintDialog.saveDefaultSettings();
    },

    selectCurrentMapScale: function () {

        var currentScale = Math.round(Beacon.MapJS.map.getScale() / 12.0);

        if (Beacon.PrintDialog.loaded) {
            var currentSelectedScale = $("#print_mapScale").val();

            var opt = $("#print_mapScale option[value='CURRENT']");

            if (currentScale < 5280) {
                opt.text("Current scale: 1in = " + currentScale + "ft");
            } else {
                opt.text("Current scale: 1in = " + Math.round(currentScale / 5280) + "mi");
            }

            return;
        }

        $("#print_mapScale").empty();

        $("#print_mapScale").append($("<option value='FIT'>Current view</option>"));

        var opt = $("<option></option>");
        opt.val('CURRENT'); //currentScale);
        if (currentScale < 5280) {
            opt.text("Current scale: 1in = " + currentScale + "ft");
        } else {
            opt.text("Current scale: 1in = " + Math.round(currentScale / 5280) + "mi");
        }
        $("#print_mapScale").append(opt);

        $("#print_mapScale").append($("<option value='CUSTOM'>Custom scale...</option>"));

        $(Beacon.MapJS.map.baseLayer.scales).each(function () {

            var scale = Math.round(this / 12.0);

            var opt = $("<option></option>");
            opt.val(scale);
            if (scale < 5280) {
                opt.text("1in = " + scale + "ft");
            } else {
                opt.text("1in = " + Math.round(scale / 5280) + "mi");
            }

            if (scale == currentScale) {
                opt.attr("selected", "");
            }

            $("#print_mapScale").append(opt);
        });


        //$("#print_mapScale").append($("<option value='FIT' selected>Fit to current view</option>"));
        //setTimeout(function () {
        $("#print_customScale").val(currentScale);
        //},100);      

        //$("#print_mapScale").change(function () {
        //    var scale = $("#print_mapScale").val();
        //    if (scale === "CUSTOM") {
        //        //sdc: This would not hide which is why the setTimeout is in there.  Probably a better way to do this but I've spent way too much time trying to figure it out already
        //        console.log('show custom');
        //        setTimeout(function () {
        //            $("#print_customScaleDiv").show();
        //        }, 100);
        //    } else {
        //        console.log('scale: ' + scale);
        //        console.log('hide custom');
        //        //sdc: This would not hide which is why the setTimeout is in there.  Probably a better way to do this but I've spent way too much time trying to figure it out already
        //        setTimeout(function () {
        //            $("#print_customScaleDiv").hide();
        //        }, 100);                
        //    }
        //}).change();
    },


    bindControl: function (element, data, prop) {

        var e = $(element);
        var inputType = e.attr('type');
        var nodeName = e[0].nodeName;

        if (inputType === 'text' || nodeName.toLowerCase() === 'select') {

            //set initial value:
            $(element).val(data[prop]);

            //track future changes:
            $(element).on("change keypress", function () {
                data[prop] = $(element).val();
            });

        }

        if (inputType === 'checkbox') {

            //set initial value:
            $(element).prop('checked', data[prop]);

            //track future changes:
            $(element).on("change keypress", function () {
                data[prop] = $(element).prop('checked');
            });

        }


    },

    printSetup: function () {

        if (Beacon.MapJS) {
            Beacon.PrintDialog.selectCurrentMapScale();
        }

        Beacon.PrintDialog.initializeSettingsDialog();

        if (Beacon.MapJS) {
            //Beacon.PrintDialog.selectCurrentMapScale();
            $(".needs-map").show();
        } else {
            $(".needs-map").hide();
        }

        $('#printSetupDialog').modal({
            backdrop: 'static', // WCAG requirement
            keyboard: true
        }).on({
            'shown.bs.modal': function () {
                // wcag - need to focus on first focusable item in the dialog
                $("#print_paperSize").focus();
            }
        });

    },

    print: function () {

        var settings = JSON.stringify(Beacon.PrintDialog.settings);

        var isMap = (!!Beacon.MapJS);

        var bbox;
        var keyvalue;
        var mapScale;

        if (isMap) {

            bbox = "&bbox=" + Beacon.MapJS.map.getExtent().toBBOX(0);

            keyvalue = Beacon.MapJS.selectionLayer.keyValue;

            if ($("#print_mapScale").val() === 'CUSTOM') {
                mapScale = "&Scale=" + $("#print_customScale").val();
            }
            else if ($("#print_mapScale").val() === 'CURRENT') {
                var currentScale = Math.round(Beacon.MapJS.map.getScale() / 12.0);
                mapScale = "&Scale=" + currentScale;
            }
            else {
                mapScale = "&Scale=" + ($("#print_mapScale").val() || "FIT");
            }

        } else {
            keyvalue = mapConfig.KeyValue;
            bbox = "";
            mapScale = "";
        }

        var url = Beacon.Tabs.getUrl(mapConfig.LayerId,
            mapConfig.PageTypeId,
            mapConfig.PageId,
            keyvalue,
            'RenderPdf.aspx') +
            bbox +
            mapScale +
            "&Q=" + (new Date()).getTime() +
            "&Options=" + encodeURIComponent(settings);

        window.open(url, "_blank");

        Beacon.GA.TrackEvent(isMap ? 'PrintMap' : 'Print', 'Click');

        $('#printSetupDialog').modal('hide');
    },

    printViaBrowser: function () {
        try {
            if (Beacon.localStorage) {
                var id = Beacon.localStorage.getItem("PrintLayout");
                Beacon.GA.TrackEvent('PrintLayout', id);
            }
        } catch(ex) {}
        window.print();
    },

    setPrintLayout: function (layoutId) {

        var id = Beacon.PrintDialog.validatePrintLayoutId(layoutId);

        $("BODY")
            //.removeClass("print-layout-0")
            .removeClass("print-layout-1")
            .removeClass("print-layout-2")
            .removeClass("print-layout-3")
            .addClass("print-layout-" + id);

        $(".main-toolbar #printSettingsDropdown li  a").attr("aria-checked", "false");
        $(".main-toolbar #printSettingsDropdown li.print-layout-" + id + " a").attr("aria-checked", "true");

        if (Beacon.localStorage) {
            Beacon.localStorage.setItem("PrintLayout", id);
        }

    },

    validatePrintLayoutId: function (layoutId) {
        try {
            var id = parseInt(layoutId);
            if (isNaN(id) || id < 1 || id > 3) { id = 1; }
            return id;
        } catch (ex) {
            return 1;
        }
    },

    CLASS_NAME: "Beacon.PrintDialog"
};
// Google analytics helper
Beacon.GA = {

    TrackEvent: function (action, label) {
        gtag && gtag('event', action, {
            'event-label': label
        });
    },

    CLASS_NAME: 'Beacon.GA'
};

// *** Based on Service Calling Proxy Class (http://www.west-wind.com/weblog/posts/324917.aspx)
/*
*
*   JDP: added scope parameter to retain "this" context on callbacks & fixed errors in error callback mechanism
*
*/
function wcfServiceProxy(serviceUrl, extraQueryString) {

    var _I = this;
    this.serviceUrl = serviceUrl;
    this.extraQueryString = extraQueryString;

    // *** Call a wrapped object
    this.invoke = function (method, timeout, data, callback, error, bare, scope, scopeArgs) {
        // *** Convert input data into JSON - REQUIRES Json2.js
        var json = JSON.stringify(data);

        // *** The service endpoint URL        
        var url = _I.serviceUrl + method + '?QPS=' + _I.extraQueryString;

        $.ajax({
            url: url,
            data: json,
            type: "POST",
            processData: false,
            contentType: "application/json",
            timeout: timeout,
            dataType: "text",  // not "json" we'll parse
            success:
                    function (res) {
                        if (!callback) return;

                        // *** Use json library so we can fix up MS AJAX dates
                        var result = JSON.parse(res);

                        // *** Bare message IS result
                        if (bare)
                        { callback.call(scope, result, scopeArgs); return; }

                        // *** Wrapped message contains top level object node
                        // *** strip it off
                        for (var property in result) {
                            callback.call(scope, result[property], scopeArgs);
                            break;
                        }
                    },
            error: function (xhr) {
                if (!error) return;

                try {
                    var msg = xhr.status + ' ' + xhr.statusText;

                    if (xhr.responseText) {
                        msg += ' -- ' + xhr.responseText;

                        //                        var err = JSON.parse(xhr.responseText);
                        //                        if (err)
                        //                            error.call(scope, err, scopeArgs);
                        //                        else
                    }
                    error.call(scope, msg, scopeArgs)
                } catch (ex) {
                    error.call(scope, "Unknown server error.", scopeArgs)
                }
                return;
            }
        });
    }
}

// fake localStorage support for ie7, wkhtmltopdf,  etc

Beacon.localStorage = window.localStorage;

if (window.localStorage) {
    try {
        // then test to see if it really works
        window.localStorage.setItem("~~~", "!");
        window.localStorage.removeItem("~~~");
    } catch (err) {
        Beacon.localStorage = null;
    }
}

if (!Beacon.localStorage) {
    console.warn("Enabling simulated localStorage")
    var ls = {
        getItem: function (k) { return ls[k] },
        removeItem: function (k) { delete ls[k] },
        setItem: function (k, v) { ls[k] = v },
        clear: function () { ls = {}; }
    };
    Beacon.localStorage = ls;
}

Beacon.sessionStorage = window.sessionStorage;

if (window.sessionStorage) {
    try {
        // then test to see if it really works
        window.sessionStorage.setItem("~~~", "!");
        window.sessionStorage.removeItem("~~~");
    } catch (err) {
        Beacon.sessionStorage = null;
    }
}

if (!Beacon.sessionStorage) {
    console.warn("Enabling simulated sessionStorage")
    var ss = {
        getItem: function () { },
        removeItem: function () { },
        setItem: function () { },
        clear: function () { }
    };
    Beacon.sessionStorage = ss;
}

// Wexford AppIntegration Support

var parcelId = "";
var ddlWexfordAction;
var wexford;

function Wexford_Action()
{
    var actionId = ddlWexfordAction.value;
    if(actionId==-1) return false;

    //ddlWexfordAction.disabled = true;

    try {
        wexford.Show(parcelId,actionId);
        }
    catch(ex) {
        alert("Unable to display: " + ex);
    }
    
    //ddlWexfordAction.disabled = false;

    ddlWexfordAction.options[0].selected=true;
    
    return false;

}

function Wexford_SetMenuVisibility(visible)
{
    if(visible) {
        document.getElementById("wexfordActionDiv").style.display = '';
        }
    else {
        document.getElementById("wexfordActionDiv").style.display = 'none';
        }
}

function Wexford_ParseURL()
{
    var url = document.location;

    var r = /keyvalue=([^\&\s]+)/i; //find key=___
    var a = r.exec(url);
    
    if(a) {
        parcelId = a[1];
        return true;
        }
    else {
        return false;
        }

}


function Wexford_Init()
{   
    ddlWexfordAction = document.getElementById("ddlWexfordAction");
    
    try {
    
        if(Wexford_ParseURL() == false) {
            Wexford_SetMenuVisibility(false);
            return false;
            }

	try {
          wexford = new ActiveXObject("ripple.BrowserExtension.ActionInvoker");
	} catch(ex) {}

        if(!wexford) {
          wexford = new ActiveXObject("Wexford.BrowserExtension.ActionInvoker");
          }
          
        var actionCount = wexford.GetActionCount();
        if(actionCount==0) {
            Wexford_SetMenuVisibility(false);
            return false;
            }
            
        for(i=0; i<actionCount; i++) {
            var oOption = document.createElement("OPTION");
            ddlWexfordAction.add(oOption);
            oOption.innerText = wexford.GetActionName(i);
            oOption.value = i;
            }          
            
        Wexford_SetMenuVisibility(true);
        
        }
        
    catch(ex) {
        Wexford_SetMenuVisibility(false);
        return false;
        }

}